import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import moment from 'moment'
import { formatStrapiText } from '@utils/methods'

const ClaimNoticeFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const workProgressList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_workProgress_not_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.notDone,
			key: 'select_workProgress_not_done'
		},
		{
			label: pageAssets?.select_workProgress_permit_submitted,
			value: REQUEST_CONFIGS_KEYS.workProgress.permitSubmitted,
			key: 'select_workProgress_permit_submitted'
		},
		{
			label: pageAssets?.select_workProgress_already_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.alreadyDone,
			key: 'select_workProgress_already_done'
		}
	]

	const radioListIsConcernedDiffFromApplicant: RadioList[] = [
		{
			label:
				pageAssets.request_form_claimNotice_isConcernedDiffFromApplicant_firstOption_label,
			value: 'false',
			key: 'request_form_claimNotice_isConcernedDiffFromApplicant_firstOption_label'
		},
		{
			label:
				pageAssets.request_form_claimNotice_isConcernedDiffFromApplicant_secondOption_label,
			value: 'true',
			key: 'request_form_claimNotice_isConcernedDiffFromApplicant_secondOption_label'
		}
	]

	const radioClaimNature: RadioList[] = [
		{
			label: pageAssets?.request_form_claimNotice_claimNature_snowRemoval_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.snowRemoval,
			key: 'request_form_claimNotice_claimNature_snowRemoval_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_flooding_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.flooding,
			key: 'request_form_claimNotice_claimNature_flooding_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_flooding_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_roadWeatheringDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage,
			key: 'request_form_claimNotice_claimNature_roadWeatheringDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_plumberFees_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
			key: 'request_form_claimNotice_claimNature_plumberFees_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_plumberFees_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_roadWorkDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.roadWorkDamage,
			key: 'request_form_claimNotice_claimNature_roadWorkDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_carAccident_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.carAccident,
			key: 'request_form_claimNotice_claimNature_carAccident_label',
			toolTip: true,
			toolTipLabel:
				pageAssets?.request_form_claimNotice_claimNature_carAccident_toolTip_label
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_bodilyInjury_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury,
			key: 'request_form_claimNotice_claimNature_bodilyInjury_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_trashCollectionDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage,
			key: 'request_form_claimNotice_claimNature_trashCollectionDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_treeFall_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.treeFall,
			key: 'request_form_claimNotice_claimNature_treeFall_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_waterMainBreak_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak,
			key: 'request_form_claimNotice_claimNature_waterMainBreak_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_seizedGoodsDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.seizedGoodsDamage,
			key: 'request_form_claimNotice_claimNature_seizedGoodsDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_towingDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.towingDamage,
			key: 'request_form_claimNotice_claimNature_towingDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_policeInterventionDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.policeInterventionDamage,
			key: 'request_form_claimNotice_claimNature_policeInterventionDamage_label'
		},
		{
			label:
				pageAssets?.request_form_claimNotice_claimNature_firefighterDamage_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.firefighterDamage,
			key: 'request_form_claimNotice_claimNature_firefighterDamage_label'
		},
		{
			label: pageAssets?.request_form_claimNotice_claimNature_other_label,
			value: REQUEST_CONFIGS_KEYS.claimNature.other,
			key: 'request_form_claimNotice_claimNature_other_label'
		}
	]

	const radioListYesOrNo: RadioList[] = [
		{
			label: pageAssets.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioListOwnerOrRenter: RadioList[] = [
		{
			label: pageAssets.select_ownerStatus_owner,
			value: REQUEST_CONFIGS_KEYS.tenantOrOwner.owner,
			key: 'select_ownerStatus_owner'
		},
		{
			label: pageAssets.select_ownerStatus_tenant,
			value: REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant,
			key: 'select_ownerStatus_tenant'
		}
	]

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_claimNotice_firstSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_claimNotice_isConcernedDiffFromApplicant_label
					}
				</b>
				<br />
				{
					pageAssets[
						radioListIsConcernedDiffFromApplicant.find(
							(claim) =>
								claim.value ==
								request.incidentForm?.isConcernedDiffFromApplicant?.toString()
						)?.key!
					]
				}
			</p>

			{request.incidentForm?.isConcernedDiffFromApplicant == true && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{request.incidentForm?.concernedInfo?.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{request.incidentForm?.concernedInfo?.first_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_email} </b>
						<br />
						{request.incidentForm?.concernedInfo?.email}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_telephone} </b>
						<br />
						{request.incidentForm?.concernedInfo?.telephone}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_address} </b>
						<br />
						{request.incidentForm?.concernedInfo?.address}
					</p>

					{request.incidentForm?.concernedInfo?.appartment && (
						<p className={pageUtils.classes.paragraph}>
							<b> {formatStrapiText(pageAssets?.label_apartment_office)} </b>
							<br />
							{request.incidentForm?.concernedInfo?.appartment}
						</p>
					)}

					{request.incidentForm?.concernedInfo?.city && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_city} </b>
							<br />
							{request.incidentForm?.concernedInfo?.city}
						</p>
					)}

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_state} </b>
						<br />
						{request.incidentForm?.concernedInfo?.state}
					</p>

					{request.incidentForm?.concernedInfo?.postal_code && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_postalCode} </b>
							<br />
							{request.incidentForm?.concernedInfo?.postal_code}
						</p>
					)}

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_country} </b>
						<br />
						{request.incidentForm?.concernedInfo?.country}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_claimNotice_secondSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_claimNotice_claimNature_label}</b>
				<br />
				{
					pageAssets[
						radioClaimNature.find(
							(nature) =>
								nature.value == request.incidentForm?.claimNature?.toString()
						)?.key!
					]
				}
			</p>

			{request.incidentForm?.claimNature?.toString() ===
				REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury && (
				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets?.request_form_claimNotice_materialDamage_label}</b>
					<br />

					{
						pageAssets[
							radioListYesOrNo.find(
								(item) =>
									item.value == request.incidentForm?.materialDamage?.toString()
							)?.key!
						]
					}
				</p>
			)}
			{request.incidentForm?.claimNature?.toString() ===
				REQUEST_CONFIGS_KEYS.claimNature.trashCollectionDamage && (
				<p>
					<b>
						{pageAssets?.request_form_claimNotice_isDamageLimitedToBin_label}
					</b>
					<br />
					{
						pageAssets[
							radioListYesOrNo.find(
								(item) =>
									item.value ==
									request.incidentForm?.isDamageLimitedToBin?.toString()
							)?.key!
						]
					}
				</p>
			)}
			{[
				REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage,
				REQUEST_CONFIGS_KEYS.claimNature.carAccident,
				REQUEST_CONFIGS_KEYS.claimNature.towingDamage
			].includes(request.incidentForm?.claimNature?.toString()!) && (
				<>
					<p>
						<b>
							{
								pageAssets?.request_form_claimNotice_isOwnerOfDamagedVehicle_label
							}
						</b>
						<br />
						{
							pageAssets[
								radioListYesOrNo.find(
									(item) =>
										item.value ==
										request.incidentForm?.isOwnerOfDamagedVehicle?.toString()
								)?.key!
							]
						}
					</p>

					{request.incidentForm?.isOwnerOfDamagedVehicle === false && (
						<p>
							<b>
								{
									pageAssets?.request_form_claimNotice_actualOwnerOfDamagedVehicle_label
								}
							</b>
							<br />
							{request.incidentForm?.actualOwnerOfDamagedVehicle}
						</p>
					)}
				</>
			)}

			{[
				REQUEST_CONFIGS_KEYS.claimNature.flooding,
				REQUEST_CONFIGS_KEYS.claimNature.plumberFees,
				REQUEST_CONFIGS_KEYS.claimNature.waterMainBreak
			].includes(request.incidentForm?.claimNature?.toString()!) && (
				<>
					<p>
						<b>{pageAssets?.request_form_claimNotice_isOwnerOrRenter_label}</b>
						<br />
						{
							pageAssets[
								radioListOwnerOrRenter.find(
									(item) =>
										item.value ==
										request.incidentForm?.isOwnerOrRenter?.toString()
								)?.key!
							]
						}
					</p>

					{request.incidentForm?.isOwnerOrRenter?.toString() ===
						REQUEST_CONFIGS_KEYS.tenantOrOwner.tenant && (
						<p>
							<b>
								{
									pageAssets?.request_form_claimNotice_actualOwnerOfDamagedVehicle_label
								}
							</b>
							<br />
							{request.incidentForm?.actualOwnerOfDamagedVehicle}
						</p>
					)}
				</>
			)}

			{request.incidentForm?.claimNature?.toString() ===
				REQUEST_CONFIGS_KEYS.claimNature.towingDamage && (
				<>
					<p>
						<b>
							{
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label
							}
						</b>
						<br />
						{request.incidentForm?.vehicleInformation?.brand}
					</p>

					<p>
						<b>
							{
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label
							}
						</b>
						<br />
						{request.incidentForm?.vehicleInformation?.model}
					</p>

					<p>
						<b>
							{
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
							}
						</b>
						<br />
						{request.incidentForm?.vehicleInformation?.color}
					</p>

					<p>
						<b>
							{
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label
							}
						</b>
						<br />
						{request.incidentForm?.vehicleInformation?.registration_number}
					</p>
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_claimNotice_incidentLocation_label} </b>
				<br />
				{request.incidentForm?.incidentLocation}
			</p>

			{request.incidentForm?.incidentDate && (
				<p className={pageUtils.classes.paragraph}>
					<b> {pageAssets.request_form_claimNotice_incidentDate_label} </b>
					<br />
					{moment(request.incidentForm?.incidentDate!)
						.utc()
						.format('DD/MM/YYYY HH:mm')}
				</p>
			)}

			{request.incidentForm?.damageDiscoveryDate && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{' '}
						{pageAssets.request_form_claimNotice_damageDiscoveryDate_label}{' '}
					</b>
					<br />
					{pageUtils.getLocaleDate(request.incidentForm?.damageDiscoveryDate!)}
				</p>
			)}

			{request.incidentForm?.policeReportNumber && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{' '}
						{pageAssets.request_form_claimNotice_policeReportNumber_label}{' '}
					</b>
					<br />
					{request.incidentForm?.policeReportNumber}
				</p>
			)}

			{request.incidentForm?.request311Number && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_claimNotice_request311Number_label}</b>
						<br />
						{request.incidentForm?.request311Number}
					</p>
				</>
			)}

			{request.incidentForm?.damageCauseDescription && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_claimNotice_damageCauseDescription_label}
						</b>
						<br />
						{request.incidentForm?.damageCauseDescription}
					</p>
				</>
			)}

			{request.incidentForm?.damageDescription && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_claimNotice_damageDescription_label}</b>
						<br />
						{request.incidentForm?.damageDescription}
					</p>
				</>
			)}
		</>
	)
}

export default ClaimNoticeFormDetails
