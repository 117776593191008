import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { formatStrapiText } from '@utils/methods'
import { SelectList } from '@utils/request'
import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import moment from 'moment'
import TableDetailsModal from '../modals/tableDetailsModal'

const TreePlantingFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const plantedTreeLocationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_plantedTreeLocation_frontYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.frontYard,
			key: 'select_plantedTreeLocation_frontYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_backYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.backYard,
			key: 'select_plantedTreeLocation_backYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_sideYard,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.sideYard,
			key: 'select_plantedTreeLocation_sideYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_shoreEdge,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.shoreEdge,
			key: 'select_plantedTreeLocation_shoreEdge'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_vacantLand,
			value: REQUEST_CONFIGS_KEYS.plantedTreeLocation.vacantLand,
			key: 'select_plantedTreeLocation_vacantLand'
		}
	]

	const [isFirstModalOpen, setIsFirstModalOpen] = useState<boolean>(false)

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{formatStrapiText(pageAssets?.request_form_step_map_location)}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{formatStrapiText(pageAssets?.label_address)} </b>
				<br />
				{request?.incidentForm?.buildingAddress}
				<br />
				{request?.incidentForm?.buildingCity}{' '}
				{request?.incidentForm?.buildingPostalCode}
			</p>

			{request?.incidentForm?.buildingCadastralNumber && (
				<p className={pageUtils.classes.paragraph}>
					<b>{formatStrapiText(pageAssets?.label_cadastral_number)}</b>
					<br />
					{request?.incidentForm?.buildingCadastralNumber}
				</p>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{formatStrapiText(
						pageAssets?.request_form_locationOwnedMoreThenAYear_label
					)}{' '}
				</b>
				<br />
				{String(request?.incidentForm?.isOverYearOwner) == 'true'
					? pageAssets?.request_form_select_yes
					: pageAssets?.request_form_select_no}
			</p>

			{String(request?.incidentForm?.isOverYearOwner) == 'false' && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{formatStrapiText(
								pageAssets?.request_form_notarialRegistrationDate_label
							)}{' '}
						</b>
						<br />
						{pageUtils.getLocaleDate(
							request.incidentForm?.notarialRegistrationDate!
						)}
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{formatStrapiText(
								pageAssets?.request_form_notarialRegistrationNumber_label
							)}{' '}
						</b>
						<br />
						{request?.incidentForm?.notarialRegistrationNumber}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{formatStrapiText(
					pageAssets?.request_form_treePlanting_planting_information_title
				)}
			</h4>

			<p>
				<span
					onClick={() => {
						setIsFirstModalOpen(true)
					}}
					className={pageUtils.classes.viewDetailsSpan}
				>
					<b>{pageAssets.request_form_show_details}</b>
				</span>
			</p>

			<TableDetailsModal
				isModalOpen={isFirstModalOpen}
				close={() => {
					setIsFirstModalOpen(false)
				}}
				data={request?.incidentForm?.plantedTreeList}
				title={pageAssets.request_form_treePlanting_planting_information_title}
				headers={[
					pageAssets?.request_form_treePlanting_plantedtreeSpecie_label,
					pageAssets?.request_form_treePlanting_plantedtreeDiameter_label,
					pageAssets?.request_form_treePlanting_plantedtreeHeight_label,
					pageAssets?.request_form_treePlanting_plantedtreeCost_label,
					pageAssets?.request_form_treePlanting_plantedtreeLocation_label,
					pageAssets?.request_form_treePlanting_plantaion_date
				]}
				attributes={[
					{ name: 'species', type: 'string' },
					{ name: 'diameter', type: 'string' },
					{ name: 'height', type: 'string' },
					{ name: 'cost', type: 'string' },
					{ name: 'location', type: 'list', list: plantedTreeLocationList },
					{ name: 'planting_date', type: 'date' }
				]}
			/>
		</>
	)
}

export default TreePlantingFormDetails
