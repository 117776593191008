import React, { FC, useContext } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { joinClasses } from '@utils/styles'
import * as pageUtils from '@components/ui/historyCard/__index.utils'

const DesktopView: FC = ({ children }) => {
	const { pageData } = useContext(AppStateContext)

	return (
		<div
			className={joinClasses([
				pageUtils.classes.historyCard,
				pageUtils.classes.desktopView
			])}
		>
			<h3 className={pageUtils.classes.h3}>
				{pageData?.assets?.label_history}
			</h3>
			<div
				style={{ overflowX: 'auto' }}
				className={joinClasses([
					pageUtils.classes.desktopContent,
					pageUtils.classes.content
				])}
			>
				{children}
			</div>
		</div>
	)
}

export default DesktopView
