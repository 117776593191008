import React, { FC } from 'react'
import * as pageUtils from './__details.utils'

const ClothDiapersFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const subvention_chosen = {
		0: 'select_clothDiapers_firstQuestion_firstOption',
		1: 'select_clothDiapers_firstQuestion_secondOption'
	}

	return (
		<>
			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_clothDiapers_subventionChosen_label} </b>
				<br />
				{pageAssets[subvention_chosen[request.incidentForm?.subventionChosen!]]}
			</p>

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.requestForm_clothDiapper_identification_of_the_child}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_clothDiapers_childFirstName_label} </b>
				<br />
				{request.incidentForm?.childFirstName}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_clothDiapers_childLastName_label} </b>
				<br />
				{request.incidentForm?.childLastName}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_clothDiapers_childBirthDate_label} </b>
				<br />
				{pageUtils.getLocaleDate(request.incidentForm?.childBirthDate!)}
			</p>

			{request.incidentForm?.subventionChosen == 1 && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets.request_form_clothDiapers_smallTitle_label}
					</h4>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_clothDiapers_adultResidentNumber_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.adultResidentNumber}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_clothDiapers_childrenResidentNumber_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.childrenResidentNumber}
					</p>
				</>
			)}
		</>
	)
}

export default ClothDiapersFormDetails
