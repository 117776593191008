import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import { RadioList } from '@utils/request'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const BikeSharingFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const grantForWhomList: RadioList[] = [
		{
			label: pageAssets?.select_carSharing_forWhom_firstOption,
			value: REQUEST_CONFIGS_KEYS.bikeSharingForWhom.firstOption,
			key: 'select_carSharing_forWhom_firstOption'
		},
		{
			label: pageAssets?.select_bikeSharing_forWhom_childrenOption,
			value: REQUEST_CONFIGS_KEYS.bikeSharingForWhom.secondOption,
			key: 'select_bikeSharing_forWhom_secondOption'
		},
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_secondOption,
			value: REQUEST_CONFIGS_KEYS.bikeSharingForWhom.thirdOption,
			key: 'select_sustainableHygiene_forWhom_secondOption',
			extraText:
				pageAssets?.select_sustainableHygiene_forWhom_secondOption_extra
		}
	]

	const radioListSubsidyReceived: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: REQUEST_CONFIGS_KEYS.carSharingSubsidyReceived.firstOption,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: REQUEST_CONFIGS_KEYS.carSharingSubsidyReceived.secondOption,
			key: 'request_form_select_no'
		}
	]
	const radioListPreviousSubscription: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]
	const radioListSubscriptionType: RadioList[] = [
		{
			label: pageAssets?.request_form_bikeSharing_subscriptionType_firstOption,
			value: REQUEST_CONFIGS_KEYS.bikeSharingSubscription.firstOption,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_bikeSharing_subscriptionType_secondOption,
			value: REQUEST_CONFIGS_KEYS.bikeSharingSubscription.secondOption,
			key: 'request_form_select_no'
		}
	]
	const isGrantforMyself = () => {
		return (
			request.incidentForm?.grantForWhom?.toString() ==
			REQUEST_CONFIGS_KEYS.bikeSharingForWhom.firstOption
		)
	}

	const isGrantforBeneficiary = () => {
		return (
			request.incidentForm?.grantForWhom?.toString() ==
			REQUEST_CONFIGS_KEYS.bikeSharingForWhom.thirdOption
		)
	}

	const isGrantforChildren = () => {
		return (
			request.incidentForm?.grantForWhom?.toString() ==
			REQUEST_CONFIGS_KEYS.bikeSharingForWhom.secondOption
		)
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_sustainableHygiene_forWhom_label}</b>
				<br />
				<span>
					{getLabelFromList(
						grantForWhomList,
						request.incidentForm?.grantForWhom
					)}
				</span>
			</p>

			{isGrantforBeneficiary() && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_fireHydrantsTankFilling_step2_title}
					</h4>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_firstName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryFirstName}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_lastName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryLastName}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_address}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryAddress}</span>
					</p>

					{request.incidentForm?.beneficiaryAppartment && (
						<p className={pageUtils.classes.paragraph}>
							<b>{pageAssets?.apartment_label}</b>
							<br />
							{request.incidentForm?.beneficiaryAppartment}
						</p>
					)}
				</>
			)}

			{isGrantforChildren() && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.select_bikeSharing_forWhom_childrenOption}
					</h4>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_firstName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryChildFirstName}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_lastName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryChildLastName}</span>
					</p>
				</>
			)}

			{/* Pour moi-même ( Juste le titre ) */}
			{isGrantforMyself() && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.select_form_sustainableHygiene_situation_firstOption}
					</h4>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_bikeSharing_subscription_Info}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_bikeSharing_previousSubscription_label}</b>
				<br />
				<span>
					{getLabelFromList(
						radioListPreviousSubscription,
						request.incidentForm?.previousSubscription?.toString()
					)}
				</span>
			</p>

			<h5 className={pageUtils.classes.h5}>
				{pageAssets?.select_form_bikeSharing_current_subscription}
			</h5>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_carSharing_subscriptionDate_label}</b>
				<br />
				<span>
					{pageUtils.getLocaleDate(
						request.incidentForm?.subscriptionDate ?? ''
					)}
				</span>
			</p>
			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_bikeSharing_subscriptionType_label}</b>
				<br />
				<span>
					{getLabelFromList(
						radioListSubscriptionType,
						request.incidentForm?.subscriptionType?.toString()
					)}
				</span>
			</p>
		</>
	)
}

export default BikeSharingFormDetails
