import React, { FC } from 'react'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import * as pageUtils from './__details.utils'
import * as formUtils from '../../requestForms/__forms.utils'
import { RadioList } from '@utils/request'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import Text from '../text'

const TemporaryPesticideApplicationPermitForIndividualsDetails: FC<pageUtils.Props> =
	({ pageAssets, request }) => {
		const seventhQuestionList: RadioList[] = [
			{
				label: pageAssets?.label_less_than_one_meter,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.lessThanOneM,
				key: 'label_less_than_one_meter'
			},
			{
				label: pageAssets?.label_one_to_five_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.oneToFiveM,
				key: 'label_one_to_five_metres'
			},
			{
				label: pageAssets?.label_five_to_ten_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.fiveToTenM,
				key: 'label_five_to_ten_metres'
			},
			{
				label: pageAssets?.label_more_than_ten_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.moreThanTenM,
				key: 'label_more_than_ten_metres'
			}
		]

		const getLabelsFromString = (
			list: formUtils.CheckboxType[],
			answer?: string
		): string[] => {
			return list
				.filter((element) => answer?.includes(element.value))
				.map((item) => item.key)
		}

		const getLabelFromList = (list, value) => {
			return list?.find((element) => element.value == value?.toString())?.label
		}

		return (
			<>
				<h4 className={pageUtils.classes.h4}>
					{formatStrapiText(
						pageAssets?.request_form_step_map_information_relating_to_the_property
					)}
				</h4>

				<p className={pageUtils.classes.paragraph}>
					<span>{request.incidentForm?.propertyAddress}</span>
					<br />
					<span>{request.incidentForm?.propertyExCity} </span>

					<span> {request.incidentForm?.propertyPostalCode}</span>
					<br />
					{request.incidentForm?.propertyLotNumber && (
						<>
							<b>{pageAssets.lot_number_label}</b> <br />
							<span>{request.incidentForm?.propertyLotNumber}</span>
						</>
					)}
				</p>

				{/* <div>
					<strong>
						{pageAssets?.label_is_land_owner}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.propertyIsOwner?.toString()}
					</span>
				</div> */}

				<h4 className={pageUtils.classes.h4}>
					{
						pageAssets?.request_form_temporaryUseOfPesticide_information_about_the_entrepreneur
					}
				</h4>
				<div>
					<strong>
						{pageAssets?.request_form_temporaryUseOfPesticide_company_name}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.contractorCompanyName}
					</span>
				</div>

				<br />

				<div>
					<strong>
						{pageAssets?.request_form_temporaryUseOfPesticide_license_number}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.contractorPermisNumber}
					</span>
				</div>

				<br />

				<div>
					<strong>{pageAssets?.label_telephone}</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.contractorTelephone}
					</span>
				</div>

				<h4 className={pageUtils.classes.h4}>
					{
						pageAssets?.request_form_temporaryUseOfPesticide_infestation_information
					}
				</h4>

				<div>
					<strong>
						{pageAssets?.request_form_temporaryUseOfPesticide_infestation_type}
					</strong>
					<span className={pageUtils.classes.paragraph}>
						{getLabelsFromString(
							formUtils.infestationListTypeAndValues,
							request.incidentForm?.typeOfInfestation
						).map((answerLabel, index) => {
							return (
								<p
									className={pageUtils.classes.listMargin}
									key={`${answerLabel}-${index}-infestactionType`}
								>
									{pageAssets[answerLabel]}
								</p>
							)
						})}
						{request.incidentForm?.typeOfInfestationOther
							?.split(', ')
							.map((answer, index) => {
								return (
									<p
										className={pageUtils.classes.paragraph}
										key={`${answer}-${index}-infestactionType`}
									>
										{answer}
									</p>
								)
							})}
					</span>
				</div>

				<br />

				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_location_of_infestation
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{getLabelsFromString(
							formUtils.locationOfInfestationListAndValues,
							request.incidentForm?.locationOfInfestation
						).map((answerLabel, index) => {
							return (
								<p
									className={pageUtils.classes.listMargin}
									key={`${answerLabel}-${index}-locationOfInfestation`}
								>
									{pageAssets[answerLabel]}
								</p>
							)
						})}
					</span>
				</div>
				<br />
				<div>
					<strong>
						{pageAssets?.request_form_temporaryUseOfPesticide_space_to_treat}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{getLabelsFromString(
							formUtils.spaceToBeTreatedListAndValues,
							request.incidentForm?.spaceToBeTreated
						).map((answerLabel, index) => {
							return (
								<p
									className={pageUtils.classes.listMargin}
									key={`${answerLabel}-${index}-spaceToBeTreated`}
								>
									{pageAssets[answerLabel]}
								</p>
							)
						})}
						{request.incidentForm?.spaceToBeTreatedOther
							?.split(', ')
							.map((answer, index) => {
								return (
									<p
										className={pageUtils.classes.paragraph}
										key={`${answer}-${index}-spaceToBeTreated`}
									>
										{answer}
									</p>
								)
							})}
					</span>
				</div>

				<br />

				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_area_to_be_treated
						}
					</strong>
					<span className={pageUtils.classes.listMargin}>
						<Text
							content={getLabelFromList(
								seventhQuestionList,
								request.incidentForm?.areaToBeTreated
							)}
						/>
					</span>
				</div>

				<br />

				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_expected_date_of_application
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{pageUtils.getLocaleDate(
							request.incidentForm?.expectedDateOfApplication!
						)}
					</span>
				</div>

				{/* ////////////////// */}
				<h5 className={pageUtils.classes.h5}>
					{
						pageAssets?.request_form_temporaryUseOfPesticide_infestation_historyn
					}
				</h5>
				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_history_of_the_problem
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.historyOfTheProblem}
					</span>
				</div>
				<br />
				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_control_methods_already_applied
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{getLabelsFromString(
							formUtils.controlMethodsAlreadyAppliedListValues,
							request.incidentForm?.controlMethodsAlreadyApplied
						).map((answerLabel, index) => {
							return (
								<p
									className={pageUtils.classes.listMargin}
									key={`${answerLabel}-${index}-controlMethodsAlreadyApplied`}
								>
									{pageAssets[answerLabel]}
								</p>
							)
						})}
					</span>
				</div>

				<br />

				{request.incidentForm?.specifyControlMethodsApplied && (
					<div>
						<strong>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_specify_control_methods_applied
							}
						</strong>
						<br />
						<span className={pageUtils.classes.paragraph}>
							{request.incidentForm?.specifyControlMethodsApplied}
						</span>
					</div>
				)}

				<h5 className={pageUtils.classes.h5}>
					{
						pageAssets?.request_form_temporaryUseOfPesticide_product_used_to_control_infestation
					}
				</h5>
				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_product_trade_name
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.productTradeName}
					</span>
				</div>
				<br />
				<div>
					<strong>
						{
							pageAssets?.request_form_temporaryUseOfPesticide_certification_number
						}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.certificationNumber}
					</span>
				</div>
				<br />
				<div className={pageUtils.classes.bottomMargin}>
					<strong>
						{pageAssets?.request_form_temporaryUseOfPesticide_active_ingredient}
					</strong>
					<br />
					<span className={pageUtils.classes.paragraph}>
						{request.incidentForm?.activeIngredient}
					</span>
				</div>
			</>
		)
	}

export default TemporaryPesticideApplicationPermitForIndividualsDetails
