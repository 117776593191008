import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import {
	AnimalInformation,
	AnimalType,
	RadioList,
	SelectList
} from '@utils/request'
import * as formUtils from '../../requestForms/__forms.utils'
import TableDetailsModal from '../modals/tableDetailsModal'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { classes } from '@components/requestForms/annualPermitAndTagForAnimals'
import { catIcon, dogIcon, magnifierGreyIcon } from '@images/icons'
import Text from '@components/ui/text'
import { joinClasses, makeClasses } from '@utils/styles'
import AnimalInforamtionModal from '../modals/animalInformarionModal'
import { ButtonStyle, Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'

type Classes = {
	cardColumnOrientation: string
	animalTitle: string
}

const localClasses: Classes = makeClasses({
	cardColumnOrientation: {
		[Breakpoints.widthBetween('lg', 'sidebarlg')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: '20px',
			'& .css-r1u628 ': {
				marginLeft: '0px ',
				marginRight: '30px ',
				marginTop: '10px'
			}
		}
	},
	animalTitle: {
		p: {
			margin: '0'
		}
	}
})

const AnnualPermitAndTagForAnimalDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListAgeGroup: RadioList[] = [
		{
			label: pageAssets?.request_form_65_years_and_over,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.equal_and_over_65_years,
			key: 'request_form_65_years_and_over'
		},
		{
			label: pageAssets?.request_form_between_18_and_64_years_old,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.between_18_and_64_years_old,
			key: 'request_form_between_18_and_64_years_old'
		},
		{
			label: pageAssets?.request_form_under_18,
			value: REQUEST_CONFIGS_KEYS.yearsOlder.under_18_years,
			key: 'request_form_under_18'
		}
	]

	const radioListWhatToDo: RadioList[] = [
		{
			label: pageAssets?.request_form_radio_request_new_annual_permit,
			value: REQUEST_CONFIGS_KEYS.whatToDo.request_new_annual_permit,
			key: 'request_form_radio_request_new_annual_permit'
		},
		{
			label: pageAssets?.request_form_radio_renewal_and_annual_permit,
			value: REQUEST_CONFIGS_KEYS.whatToDo.renewal_and_annual_permit,
			key: 'request_form_radio_renewal_and_annual_permit'
		},
		{
			label: pageAssets?.request_form_radio_replace_lost_or_damaged_tag,
			value: REQUEST_CONFIGS_KEYS.whatToDo.replace_lost_or_damaged_tag,
			key: 'request_form_radio_replace_lost_or_damaged_tag'
		}
	]

	const animalNumber = {
		0: pageAssets?.request_form_annualPermitAndTagForAnimals_first_animal,
		1: pageAssets?.request_form_annualPermitAndTagForAnimals_second_animal,
		2: pageAssets?.request_form_annualPermitAndTagForAnimals_third_animal,
		3: pageAssets?.request_form_annualPermitAndTagForAnimals_fouth_animal,
		4: pageAssets?.request_form_annualPermitAndTagForAnimals_fifth_animal,
		5: pageAssets?.request_form_annualPermitAndTagForAnimals_sixth_animal,
		6: pageAssets?.request_form_annualPermitAndTagForAnimals_seventh_animal,
		7: pageAssets?.request_form_annualPermitAndTagForAnimals_eighth_animal
	}

	const radioListWeightUnit: RadioList[] = [
		{
			label: pageAssets?.label_ib,
			value: REQUEST_CONFIGS_KEYS.weight_unit.lb_unit,
			key: 'label_ib'
		},
		{
			label: pageAssets?.label_kg,
			value: REQUEST_CONFIGS_KEYS.weight_unit.kg_unit,
			key: 'label_kg'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListSpecialPermit: RadioList[] = [
		{
			label: pageAssets?.label_one_year_of_special_permit,
			value: '1',
			key: 'label_one_year_of_special_permit'
		},
		{
			label: pageAssets?.label_two_years_of_special_permit,
			value: '2',
			key: 'label_two_years_of_special_permit'
		},
		{
			label: pageAssets?.label_three_years_of_special_permit,
			value: '3',
			key: 'label_three_years_of_special_permit'
		},
		{
			label: pageAssets?.label_i_dont_have_special_permit_to_renew,
			value: '0',
			key: 'label_i_dont_have_special_permit_to_renew'
		}
	]

	const reasonForRenewalList = formUtils.addLabelToList(
		formUtils.reasonForRenewalRawList,
		pageAssets
	) as SelectList[]

	const getSelectLabel = (options, value) => {
		return options.find((option) => option.value === value)
	}

	const getLabelsFromString = (
		list: RadioList[],
		answer?: string
	): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	// Dog Race
	const selectListDogRace = formUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_dog_race_list
	)
	// Cat Race
	const selectListCatRace = formUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_cat_race_list
	)
	// Color
	const selectListColor = formUtils.createSearchableList(
		pageAssets?.request_form_annualPermitAndTagForAnimals_animal_color
	)

	const raceList = {
		[AnimalType.Dog]: selectListDogRace,
		[AnimalType.Cat]: selectListCatRace
	}

	const durationOfPermitList = (
		animalType: string,
		ageGroupValue: string,
		isAssistanceAnimal?: boolean
	) => {
		// set the price for an animal based on the age group
		let price = isAssistanceAnimal
			? 0
			: ageGroupValue == radioListAgeGroup[1].value
			? 10
			: 0
		// set multiplier by 1 for cat an 2 for dog
		let multipier = animalType == AnimalType.Cat ? 1 : 2

		const unitPrice = price * multipier

		const radioListDurationOfPermit: RadioList[] = [
			{
				label: pageAssets?.label_one_year_dog?.replace(
					'$',
					`${unitPrice * 1}$`
				),
				value: '1',
				key: 'label_one_year_dog'
			},
			{
				label: pageAssets?.label_two_years_dog?.replace(
					'$',
					`${unitPrice * 2}$`
				),
				value: '2',
				key: 'label_two_years_dog'
			},
			{
				label: pageAssets?.label_three_years_dog?.replace(
					'$',
					`${unitPrice * 3}$`
				),
				value: '3',
				key: 'label_three_years_dog'
			}
		]

		return radioListDurationOfPermit
	}

	const durationOfPermitLabel = (
		animalType: string,
		ageGroupValue: string,
		isAssistanceAnimal?: boolean,
		durationValue?: string
	): string | undefined => {
		return durationOfPermitList(
			animalType,
			ageGroupValue,
			isAssistanceAnimal
		).find((permit) => permit.value === durationValue)?.label
	}

	const durationOfSpecialPermitList = (ageGroupValue: string) => {
		// set the price for an animal based on the age group
		let price = ageGroupValue == radioListAgeGroup[1].value ? 50 : 0

		const radioListDurationOfPermit: RadioList[] = [
			{
				label: pageAssets?.label_one_year_dog?.replace('$', `${price * 1}$`),
				value: '1',
				key: 'label_one_year_of_special_permit'
			},
			{
				label: pageAssets?.label_two_years_dog?.replace('$', `${price * 2}$`),
				value: '2',
				key: 'label_two_years_of_special_permit'
			},
			{
				label: pageAssets?.label_three_years_dog?.replace('$', `${price * 3}$`),
				value: '3',
				key: 'label_three_years_of_special_permit'
			},
			{
				label: pageAssets?.label_i_dont_have_special_permit_to_renew,
				value: '0',
				key: 'label_i_dont_have_special_permit_to_renew'
			}
		]

		return radioListDurationOfPermit
	}

	const getAnimalSubTitle = (
		animalInformation: AnimalInformation,
		whatToDoValue: string,
		isRenewal?: boolean,
		isDamagedMedal?: boolean
	) => {
		if (whatToDoValue == radioListWhatToDo[0].value) {
			return (
				<>
					{
						getSelectLabel(
							raceList[animalInformation.type],
							animalInformation.breed
						)?.label
					}{' '}
					{animalInformation.color && (
						<>
							, &nbsp;
							{getSelectLabel(selectListColor, animalInformation.color)?.label}
						</>
					)}
				</>
			)
		} else if (whatToDoValue == radioListWhatToDo[1].value) {
			return (
				<>
					{isRenewal
						? pageAssets?.label_renew
								?.replace('le permis', '')
								.replace('permit', '')
						: pageAssets?.label_do_not_renew}{' '}
					- {pageAssets?.label_medal}:&nbsp;
					{animalInformation?.tag_number?.trim() != '' &&
					animalInformation?.tag_number != null
						? animalInformation?.tag_number
						: pageAssets?.label_unknown}
				</>
			)
		} else if (whatToDoValue == radioListWhatToDo[2]?.value) {
			return (
				<>
					{isDamagedMedal
						? pageAssets?.label_damaged_medal
						: pageAssets?.label_lost_medal}{' '}
					- {pageAssets?.label_medal}:&nbsp;
					{animalInformation?.tag_number?.trim() != '' &&
					animalInformation?.tag_number != null
						? animalInformation?.tag_number
						: pageAssets?.label_unknown}
				</>
			)
		}
	}

	const [isFirstModalOpen, setIsFirstModalOpen] = useState<boolean>(false)

	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)
	const [modalAnimalInformation, setModalAnimalInformation] =
		useState<AnimalInformation>()
	const [currentAnimalIndex, setCurrentAnimalIndex] = useState<number>(0)

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_guardian_information}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{pageAssets?.request_form_annualPermitAndTagForAnimals_65_years_older}
				</b>
				<br />
				<span>
					{getLabelFromList(
						radioListAgeGroup,
						String(request.incidentForm?.yearsOlder)
					)}
				</span>
			</p>

			{(request.incidentForm?.otherGuardName ||
				request.incidentForm?.otherGuardFirstName ||
				request.incidentForm?.otherGuardPhoneNumber ||
				request.incidentForm?.otherGuardOtherPhoneNumber ||
				request.incidentForm?.relationshipWithGuard) && (
				<h5 className={pageUtils.classes.h5}>
					{pageAssets?.request_form_persons_to_contact_in_case_of_emergency}
				</h5>
			)}

			{request.incidentForm?.otherGuardName && (
				<div>
					<strong>{pageAssets?.label_fullName}</strong>
					<br />
					<span>{request.incidentForm?.otherGuardName}</span>
				</div>
			)}
			{request.incidentForm?.otherGuardFirstName && (
				<div>
					<strong>{pageAssets?.label_firstName}</strong>
					<br />
					<span>{request.incidentForm?.otherGuardFirstName}</span>
				</div>
			)}
			{request.incidentForm?.otherGuardPhoneNumber && (
				<div>
					<strong>{pageAssets?.label_telephone}</strong>
					<br />
					<span>{request.incidentForm?.otherGuardPhoneNumber}</span>
				</div>
			)}

			{request.incidentForm?.otherGuardOtherPhoneNumber && (
				<div>
					<strong>{pageAssets?.label_other_telephone}</strong>
					<br />
					<span>{request.incidentForm?.otherGuardOtherPhoneNumber}</span>
				</div>
			)}
			{request.incidentForm?.relationshipWithGuard && (
				<div>
					<strong>
						{
							pageAssets?.request_form_annualPermitAndTagForAnimals_relationship_with_guard
						}
					</strong>
					<br />
					<span>{request.incidentForm?.relationshipWithGuard}</span>
				</div>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.label_information_about_the_animals}
			</h4>

			<div>
				<strong>
					{
						pageAssets?.request_form_annualPermitAndTagForAnimals_what_would_you_like_to_do
					}
				</strong>
				<br />
				<span>
					{getLabelFromList(
						radioListWhatToDo,
						String(request.incidentForm?.whatToDo)
					)}
				</span>
			</div>

			<br />

			{request.incidentForm?.animalInformationList?.map(
				(animalInformation, row) => (
					<>
						<div className={classes.headerContainer}>
							<h5
								className={joinClasses([
									pageUtils.classes.h5,
									localClasses.animalTitle
								])}
							>
								{<Text content={animalNumber[row]} />}
							</h5>
						</div>
						<div
							className={joinClasses([
								classes.card,
								localClasses.cardColumnOrientation
							])}
						>
							<div className={classes.animalWrapper}>
								<img
									src={
										animalInformation.type == AnimalType.Dog ? dogIcon : catIcon
									}
									alt="animal"
									className={classes.imageIcon}
								/>
								<div>
									<strong>{animalInformation.name}</strong>
									<p style={{ marginTop: '5px', marginBottom: 0 }}>
										{getAnimalSubTitle(
											animalInformation,
											String(request.incidentForm?.whatToDo) ??
												radioListWhatToDo[0].value,
											animalInformation?.is_renewing?.toString() == 'true',
											animalInformation?.is_replacement?.toString() ==
												REQUEST_CONFIGS_KEYS.replacePermit.damaged
										)}
									</p>
								</div>
							</div>

							<div className={classes.buttonWrapper}>
								<>
									<button
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											classes.btnPadding
										])}
										onClick={() => {
											setModalAnimalInformation(animalInformation)
											setOpenDetailModal(true)
											setCurrentAnimalIndex(row)
										}}
										type="button"
									>
										{formatStrapiText(pageAssets?.label_see_details)}
									</button>
								</>
							</div>
						</div>
					</>
				)
			)}

			{request.incidentForm?.coveredByException?.toString() == 'true' && (
				<div style={{ marginBottom: '20px' }}>
					<strong>
						{
							pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception_title
						}
					</strong>
					<br />
					<span>
						{
							pageAssets?.info_form_annualPermitAndTagForAnimals_covered_exception
						}
					</span>
					<br />
				</div>
			)}

			{request.incidentForm?.whatToDo?.toString() ==
				radioListWhatToDo[1].value && (
				<div style={{ marginBottom: '20px' }}>
					<strong>{pageAssets?.label_special_permit}</strong>
					<br />
					<span>
						{getLabelFromList(
							durationOfSpecialPermitList(
								String(request.incidentForm?.yearsOlder)
							),
							String(request.incidentForm?.specialPermit)
						)}
					</span>
					<br />
				</div>
			)}

			<AnimalInforamtionModal
				isModalOpen={openDetailModal}
				animalInformation={modalAnimalInformation}
				primaryButtonText={pageAssets?.button_complete}
				close={() => {
					setOpenDetailModal(false)
				}}
				onPrimaryButton={() => {
					setOpenDetailModal(false)
				}}
				dogRaceList={selectListDogRace}
				catRaceList={selectListCatRace}
				colorList={selectListColor}
				permitDurationLabel={durationOfPermitLabel(
					modalAnimalInformation?.type ?? '',
					String(request.incidentForm?.yearsOlder) ?? '',
					String(modalAnimalInformation?.service_animal) ==
						radioListYesNo[0].value,
					String(modalAnimalInformation?.permit_term) ||
						modalAnimalInformation?.renewal_duration
				)}
				weigthUnitList={radioListWeightUnit}
				// files={}
				permitTypeList={radioListWhatToDo}
				typeOfPermit={`${request.incidentForm?.whatToDo}`}
				reasonForRenewalList={reasonForRenewalList}
			/>
		</>
	)
}

export default AnnualPermitAndTagForAnimalDetails
