import React, { FC, useContext, useState } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { RequestConsent } from '@services/models'
import Modal from '../modal'
import Text from '@components/ui/text'
import Loader from '../loader'

type props = {
	isOpen: boolean
	consent: RequestConsent
	onClose: () => void
	onSetIsOpen: () => void
}

const RevokeModal: FC<props> = ({ isOpen, onClose, onSetIsOpen, consent }) => {
	const { pageData } = useContext(AppStateContext)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const revokeConsent = async () => {
		if (consent.request) {
			setIsLoading(true)
			await consent.request.revokeConsent(consent)

			// reload page TODO: Update later for redux toolkit
			window.location.reload()

			// TODO: update
			// alert('add animation here and remove consent from list')
		}
	}

	return (
		<>
			<Modal
				title={pageData?.assets?.myRequest_do_you_wish_to_revoke_consent}
				primaryButtonText={pageData?.assets?.my_request_consent_btn_revoke}
				onPrimaryButton={revokeConsent}
				cancelButtonText={pageData?.assets?.cancel}
				showExitBtn
				isOpen={isOpen}
				onCancelBtn={onClose}
				onSetIsOpen={onSetIsOpen}
				disabled={isLoading}
			>
				{isLoading ? (
					<Loader text={pageData?.assets?.loading} />
				) : (
					<>
						<Text content={pageData?.assets?.page_myRequest_revoke_text} />
					</>
				)}
			</Modal>
		</>
	)
}

export default RevokeModal
