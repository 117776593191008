import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { SelectList } from '@utils/request'

const CrackedHousesFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const firstOwner =
		request.incidentForm?.coApplicants && request.incidentForm?.coApplicants[0]

	const secondOwner =
		request.incidentForm?.coApplicants && request.incidentForm?.coApplicants[1]
	const workProgressList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_workProgress_not_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.notDone,
			key: 'select_workProgress_not_done'
		},
		{
			label: pageAssets?.select_workProgress_permit_submitted,
			value: REQUEST_CONFIGS_KEYS.workProgress.permitSubmitted,
			key: 'select_workProgress_permit_submitted'
		},
		{
			label: pageAssets?.select_workProgress_already_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.alreadyDone,
			key: 'select_workProgress_already_done'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value)?.label
	}

	return (
		<>
			{request.incidentForm?.buildingAddress && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets.request_form_building_location}
					</h4>
					<strong className={pageUtils.classes.title}>
						{pageAssets?.request_form_address_of_the_building}
					</strong>

					<p className={pageUtils.classes.paragraph}>
						<span>{request.incidentForm?.buildingAddress}</span>
						<br />
						<span>{request.incidentForm?.applicant?.city} </span>

						<span> {request.incidentForm?.buildingPostalCode}</span>
						<br />
						<br />
						{request.incidentForm?.buildingCadastralNumber && (
							<>
								<b>{pageAssets.request_form_mapCadastral_label}</b> <br />
								<span>{request.incidentForm?.buildingCadastralNumber}</span>
							</>
						)}
					</p>
				</>
			)}

			{(firstOwner || secondOwner) && (
				<h4 className={pageUtils.classes.h4}>
					{pageAssets.request_form_otherOwnersIfAny_section_title}
				</h4>
			)}

			{firstOwner && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{firstOwner.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{firstOwner.first_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_email} </b>
						<br />
						{firstOwner.email}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_telephone} </b>
						<br />
						{firstOwner.telephone}
					</p>
				</>
			)}

			{secondOwner && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{secondOwner.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{secondOwner.first_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_email} </b>
						<br />
						{secondOwner.email}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_telephone} </b>
						<br />
						{secondOwner.telephone}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_concernedBuildingInformations_section_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_crackedHouses_buildingNumber_label} </b>
				<br />
				{request.incidentForm?.buildingNumber}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_crackedHouses_workProgress_label} </b>
				<br />
				{getLabelFromList(workProgressList, request.incidentForm?.workProgress)}
			</p>

			{request.incidentForm?.workDate && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_crackedHouses_workDate_label}</b>
						<br />

						{pageUtils.getLocaleDate(request.incidentForm?.workDate!)}
					</p>
				</>
			)}

			{request.incidentForm?.permitNumber && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_crackedHouses_permitNumber_label}</b>
						<br />
						{request.incidentForm?.permitNumber}
					</p>
				</>
			)}
		</>
	)
}

export default CrackedHousesFormDetails
