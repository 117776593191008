import React, { FC, useContext } from 'react'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import { handleAccordeon } from '@pages/auth/requests/__[id].utils'
import { joinClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { timeDisplay } from '@utils/methods'
import { getStatusBadgeLabel } from '@components/ui/requestCard'
import Text from '@components/ui/text'
import Attachments from '../Attachment'
import Consent from '../consent'
import * as pageUtils from '@pages/auth/requests/__index.utils'
import { CATEGORIES, RequestStatusEnum } from '@services/constants'
import {
	CommentsAttachment,
	Request,
	RequestConsent,
	Timeline
} from '@services/models'

type PropsType = {
	request: Request
	consentList: RequestConsent[]
	strapiRequest: any
	isOldRequest: boolean
	otherComments: string | undefined
	requestTitle: string
	onAddNewConsent: (consent: RequestConsent) => void
}

const OtherCommentsLabel = 'page_requestInfo_inputDetails'

const RequestInfo: FC<PropsType> = ({
	request,
	consentList,
	strapiRequest,
	requestTitle,
	isOldRequest,
	onAddNewConsent,
	otherComments
}) => {
	const { pageData, language, userRequest } = useContext(AppStateContext)

	return (
		<div>
			<div className={pageRequestDetailsUtils.classes.block1}>
				<h3 className={pageRequestDetailsUtils.classes.h3}>
					{pageData?.assets?.createRequest_page_summary_of_the_request}
				</h3>
				<div>
					<p className={pageRequestDetailsUtils.classes.text}>
						<b>{pageData?.assets?.request_form_othersRequests_subject}</b>
						<br />
						{request?.typeId === null ? request.title : requestTitle}
					</p>
				</div>
				<div>
					<p className={pageRequestDetailsUtils.classes.text}>
						<b>{pageData?.assets?.page_requestInfo_location}</b>
						<br />
						{request?.location?.address}
						<br />
						{request?.location?.apartment && (
							<>
								{pageData?.assets?.label_apartment}/
								{pageData?.assets?.label_office} {request?.location?.apartment}
							</>
						)}
					</p>
				</div>
				<div>
					{strapiRequest &&
						userRequest &&
						!isOldRequest &&
						userRequest
							?.getQnA(
								pageData.assets,
								strapiRequest.name,
								OtherCommentsLabel,
								pageData.requests
							)
							?.map(({ question, answer }, index) => (
								<>
									{!!answer && (
										<>
											<p
												key={`question-${index}`}
												className={pageRequestDetailsUtils.classes.text}
											>
												<b>{question}</b>
											</p>
											<div className={pageRequestDetailsUtils.classes.text}>
												<Text content={answer} />
											</div>
										</>
									)}
								</>
							))}
					<div className={pageRequestDetailsUtils.classes.text}>
						<b>{pageData?.assets?.page_requestInfo_inputDetails}</b>
						<br />
						{otherComments ? (
							<>
								<Text content={otherComments?.replace(/\n/g, '')} />
							</>
						) : (
							pageData.assets.myRequest_page_no_comment
						)}{' '}
					</div>
				</div>
				{!!request?.comments.length && (
					<div>
						{request.comments && (
							<b>{pageData?.assets?.Information_added_to_the_request}</b>
						)}
						{request.comments
							.filter(
								(comment) =>
									comment.category == CATEGORIES.ADDITIONAL_INFO_WITH_FOLLOW_UP
							)
							.map((comment, index) => (
								<div
									key={comment.id}
									className={pageUtils.classes.commentsSeparator}
								>
									<button
										id={`info-${index}`}
										className={pageRequestDetailsUtils.classes.accordion}
										style={{ borderTop: 'none' }}
										onClick={handleAccordeon}
									>
										{timeDisplay(
											language as string,
											comment.dateAdd?.UTCDateString || '',
											pageData?.assets?.label_at
										)}
									</button>
									<div className={pageRequestDetailsUtils.classes.panel}>
										<div className={pageRequestDetailsUtils.classes.text}>
											<Text content={comment.text?.replace(/\n/g, '<br>')} />
										</div>
										{comment?.attachments?.length! > 0 && (
											<div style={{ marginTop: '20px' }}>
												<Attachments files={comment?.attachments!} />
											</div>
										)}
									</div>
								</div>
							))}
					</div>
				)}
			</div>
			<div className={pageRequestDetailsUtils.classes.block1}>
				<h3 className={pageRequestDetailsUtils.classes.h3}>
					{pageData?.assets?.page_requestInfo_enclosed}
				</h3>

				{!request?.attachments.length && (
					<p>{pageData?.assets?.label_no_attachments}</p>
				)}

				<div style={{ marginTop: '20px' }}>
					<Attachments noTitle showHeader files={request?.attachments!} />
				</div>
			</div>
			<Consent
				consentList={consentList}
				onAddNewConsent={onAddNewConsent}
				className={pageRequestDetailsUtils.classes.block1}
			/>
		</div>
	)
}

export default RequestInfo
