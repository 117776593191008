import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { SelectList } from '@utils/request'

const HeritageBuildingsRevitalizationProgramDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const firstOwner =
		request.incidentForm?.coApplicants && request.incidentForm?.coApplicants[0]

	const secondOwner =
		request.incidentForm?.coApplicants && request.incidentForm?.coApplicants[1]

	const buildingTypeInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_buildingTypeInput_heritageBuilding,
			value: REQUEST_CONFIGS_KEYS.buildingType.heritageBuilding,
			key: 'select_buildingTypeInput_heritageBuilding'
		},
		{
			label: pageAssets?.select_buildingTypeInput_calvary,
			value: REQUEST_CONFIGS_KEYS.buildingType.calvary,
			key: 'select_buildingTypeInput_calvary'
		},
		{
			label: pageAssets?.select_buildingTypeInput_crossOfTheRoad,
			value: REQUEST_CONFIGS_KEYS.buildingType.crossOfTheRoad,
			key: 'select_buildingTypeInput_crossOfTheRoad'
		}
	]

	const buildingCategoryInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_buildingCategory_residential,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.residential,
			key: 'select_buildingCategory_residential'
		},
		{
			label: pageAssets?.select_buildingCategory_commercial,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.commercial,
			key: 'select_buildingCategory_commercial'
		},
		{
			label: pageAssets?.select_buildingCategory_industrial,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.industrial,
			key: 'select_buildingCategory_industrial'
		},
		{
			label: pageAssets?.select_buildingCategory_institutional,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.institutional,
			key: 'select_buildingCategory_institutional'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value)?.label
	}

	return (
		<>
			{request.incidentForm?.buildingAddress && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets.request_form_building_location}
					</h4>

					<p className={pageUtils.classes.paragraph}>
						<span>{request.incidentForm?.buildingAddress}</span>
						<br />
						<span>{request.incidentForm?.applicant?.city} </span>

						<span> {request.incidentForm?.buildingPostalCode}</span>
						<br />
						<br />
						{request.incidentForm?.buildingCadastralNumber && (
							<>
								<b>{pageAssets.request_form_mapCadastral_label}</b> <br />
								<span>{request.incidentForm?.buildingCadastralNumber}</span>
							</>
						)}
					</p>
				</>
			)}

			{(firstOwner || secondOwner) && (
				<h4 className={pageUtils.classes.h4}>
					{pageAssets.request_form_otherOwnersIfAny_section_title}
				</h4>
			)}

			{firstOwner && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{firstOwner.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{firstOwner.first_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_email} </b>
						<br />
						{firstOwner.email}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_telephone} </b>
						<br />
						{firstOwner.telephone}
					</p>
				</>
			)}

			{secondOwner && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{secondOwner.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{secondOwner.first_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_email} </b>
						<br />
						{secondOwner.email}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_telephone} </b>
						<br />
						{secondOwner.telephone}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_concernedBuildingInformations_section_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_heritageBuildingsRevitalizationProgram_buildingType_label
					}
				</b>
				<br />
				{getLabelFromList(
					buildingTypeInputList,
					request.incidentForm?.buildingType
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_heritageBuildingsRevitalizationProgram_buildingCategoryInput_label
					}
				</b>
				<br />
				{getLabelFromList(
					buildingCategoryInputList,
					request.incidentForm?.buildingCategory
				)}
			</p>
		</>
	)
}

export default HeritageBuildingsRevitalizationProgramDetails
