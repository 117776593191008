import React, { FC, useContext, useEffect, useState } from 'react'
import Modal from '../../modal'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { Colors } from '@utils/css-variables'
import { chevronDownBlueIcon } from '@images/icons'
import { makeClasses } from '@utils/styles'
import Breakpoints from '@utils/breakpoints'
import moment from 'moment'

type propsTypes = {
	isModalOpen: boolean
	close: () => void
	headers: string[]
	attributes
	data
	title: string
}

type ClassType = {
	modalContent: string
	rowNumber: string
	tableSummary: string
	tableContainer: string
	modalExtraStyles: string
	modalExtraBodyStyles: string
}

const classes: ClassType = makeClasses({
	modalContent: {
		'& fieldset': {
			margin: '0 0 30px 0',
			border: 0,
			padding: 0,
			clear: 'both',
			'& > div textarea': {
				background: Colors.white,
				width: '100%',
				height: '150px',
				display: 'block',
				border: `1px solid ${Colors.grey}`,
				padding: '8px 12px',
				fontWeight: 500,
				fontSize: '15px',
				color: Colors.grey,
				resize: 'vertical'
			}
		},
		'& label': {
			display: 'block',
			margin: '5px 0',
			padding: 0,
			fontSize: '16px',
			fontWeight: 'bold',
			color: Colors.lightBlack,
			lineHeight: '1.2em'
		},
		'& select': {
			display: 'block',
			padding: '0 12px',
			width: '100%',
			height: '40px',
			lineHeight: '25px',
			fontWeight: 500,
			fontSize: '15px',
			color: Colors.lightBlack,
			border: `1px solid ${Colors.grey}`,
			boxShadow: 0,
			appearance: 'none',
			backgroundImage: `url(${chevronDownBlueIcon})`,
			backgroundColor: Colors.white,
			backgroundRepeat: 'no-repeat,repeat',
			backgroundPosition: 'right 0.7em top 50%,0 0',
			backgroundSize: '24px'
		}
	},
	rowNumber: {
		fontWeight: '500',
		color: Colors.white,
		borderRadius: '50%',
		fontSize: '14px',
		width: '26px',
		height: '26px',
		lineHeight: '26px',
		background: Colors.lightBlue,
		textAlign: 'center',
		display: 'block'
	},

	tableSummary: {
		borderCollapse: 'collapse',
		'& td, & th': {
			fontSize: '16px',
			border: '1px solid #ccc',
			padding: '7px 15px'
		},
		'& td': { lineHeight: '28px' },
		'& th': { lineHeight: '20px' },
		'& tr:nth-child(even)': {
			backgroundColor: Colors.lightGrey4
		},
		p: {
			margin: '5px 0'
		}
	},
	modalExtraStyles: {
		maxWidth: '1000px'
	},
	modalExtraBodyStyles: {
		width: 'auto'
	},
	tableContainer: {
		overflowX: 'auto',
		[Breakpoints.maxWidth('md')]: {
			maxWidth: '630px'
		},
		[Breakpoints.maxWidth('sm')]: {
			maxWidth: '400px'
		},
		[Breakpoints.maxWidth('xs')]: {
			maxWidth: '270px'
		}
	}
})

const TableDetailsModal: FC<propsTypes> = ({
	isModalOpen,
	close,
	headers,
	attributes,
	data,
	title
}) => {
	const { pageData } = useContext(AppStateContext)

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value.toString())?.label
	}

	const getValueByType = (value, type, list) => {
		switch (type) {
			case 'date':
				if (value == null) return
				return moment.utc(value).format('DD/MM/YYYY')
			case 'list':
				return getLabelFromList(list, value)
			case 'string':
			default:
				return value
		}
	}

	return (
		<Modal
			title={title}
			cancelButtonText={pageData?.assets?.close_label}
			showExitBtn
			isOpen={isModalOpen}
			onCancelBtn={() => {
				close()
			}}
			onSetIsOpen={() => {}}
			alignBtn={'left'}
			extraStyle={classes.modalExtraStyles}
			extraBodyStyle={classes.modalExtraBodyStyles}
		>
			<div className={classes.modalContent}>
				{/* <p className={pageUtils.classes.paragraph}> */}
				<div className={classes.tableContainer}>
					<table className={classes.tableSummary}>
						<thead>
							<tr>
								<th></th>
								{headers.map((header) => {
									return (
										<th>
											<strong>{header}</strong>
										</th>
									)
								})}
							</tr>
						</thead>
						<tbody>
							{data?.map((row, index) => {
								return (
									<tr key={`row_${index}`}>
										<td>
											<span className={classes.rowNumber}>{index + 1}</span>
										</td>
										{attributes.map((attribute) => {
											return (
												<td>
													{getValueByType(
														row[attribute.name],
														attribute.type,
														attribute?.list
													)}
												</td>
											)
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
				{/* </p> */}
			</div>
		</Modal>
	)
}

export default TableDetailsModal
