import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { joinClasses } from '@utils/styles'
import * as pageUtils from '@components/ui/historyCard/__index.utils'
import Breakpoints from '@utils/breakpoints'
import TinySlider from 'tiny-slider-react'
import 'tiny-slider/dist/tiny-slider.css'

const MobileView: FC = ({ children }) => {
	const { pageData } = useContext(AppStateContext)
	const [isLastItem, setIsLastItem] = useState<boolean>(false)
	const [isFirstItem, setIsFirstItem] = useState<boolean>(false)
	const sliderRef = useRef<TinySlider>()

	const settings = {
		lazyload: true,
		nav: false,
		mouseDrag: true,
		controlsContainer: '.slider-control',
		prevButton: '.slider-control-prev',
		nextButton: '.slider-control-next',
		loop: false,
		items: 1,
		responsive: {
			[Breakpoints.values.sm]: {
				items: 2
			},
			[Breakpoints.values.xxs]: {
				items: 1
			}
		}
	}

	const updateIndexStatus = (event) => {
		const currentIndex: number = event?.displayIndex
		const itemsDisplayedCount: number = event?.items
		const itemsCount: number = event?.slideCount
		if (currentIndex == 1) {
			setIsFirstItem(true)
		} else if (currentIndex + itemsDisplayedCount - 1 === itemsCount) {
			setIsLastItem(true)
		} else {
			setIsFirstItem(false)
			setIsLastItem(false)
		}
	}

	useEffect(() => {
		const ref = sliderRef?.current?.slider?.getInfo()
		updateIndexStatus(ref)
	}, [])

	return (
		<div
			className={joinClasses([
				pageUtils.classes.historyCard,
				pageUtils.classes.mobileView
			])}
		>
			<div className={pageUtils.classes.headerStyle}>
				<h3 className={pageUtils.classes.h3}>
					{pageData?.assets?.label_history}
				</h3>
				<div className="slider-control">
					<button
						className={joinClasses([
							pageUtils.classes.carousselButton,
							pageUtils.classes.carousselPrevButton,
							`${isFirstItem ? pageUtils.classes.disabled : ''}`,
							'slider-control-prev'
						])}
					></button>
					<button
						className={joinClasses([
							pageUtils.classes.carousselButton,
							`${isLastItem ? pageUtils.classes.disabled : ''}`,
							'slider-control-next'
						])}
					></button>
				</div>
			</div>

			<TinySlider
				ref={sliderRef}
				settings={settings}
				className={joinClasses([
					pageUtils.classes.content,
					pageUtils.classes.mobileContent
				])}
				onIndexChanged={(event) => updateIndexStatus(event)}
			>
				{children}
			</TinySlider>
		</div>
	)
}

export default MobileView
