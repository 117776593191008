import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

type classes = {
	container: string
	bloc: string
	section: string
	historySection: string
	headerSection: string
	borderBottom: string
	noMarginBottom: string
	actionRow: string
	status: string
	tag: string
	open: string
	closed: string
	processing: string
	actionButtons: string
	informationLine: string
	infoQuestion: string
	infoTooltip: string
	infoAnswer: string
	infoSection: string
	paragraph: string
	actionTitle: string
	h3: string
	h4: string
	button: string
	clearInnerPadding: string
	historyCard: string
	buttonWithIcon: string
	attachments: string
	collapse: string
	notificationText: string
	table: string
	buttonContainer: string
	noMargin: string
	messageContainer: string
	description: string
	removePadding: string
}

export const classes: classes = makeClasses({
	container: {
		display: 'grid',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px',
		width: '100%',
		lineHeight: '1.62em',
		[Breakpoints.minWidth('lg')]: {
			gridTemplateColumns: '58.333333% 41.666667%'
		}
	},
	bloc: {
		background: Colors.white,
		padding: '40px',
		marginBottom: '30px'
	},
	borderBottom: {
		borderBottom: `1px solid ${Colors.lightGrey7} !important`
	},
	noMarginBottom: {
		marginBottom: '0px'
	},
	section: {
		position: 'relative',
		width: '100%',
		[Breakpoints.minWidth('lg')]: {
			paddingRight: '15px',
			paddingLeft: '15px'
		},
		[Breakpoints.maxWidth('md')]: {
			width: '100vw'
		}
	},
	headerSection: {
		borderBottom: '1px solid #e6e7e8',
		marginBottom: '0px'
	},
	actionRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		[Breakpoints.maxWidth('sm')]: {
			flexDirection: 'column-reverse',
			rowGap: '10px',
			alignItems: 'flex-start'
		},
		[Breakpoints.widthBetween('laptop', 'xl')]: {
			flexDirection: 'column-reverse',
			rowGap: '15px'
		}
	},
	status: {
		textAlign: 'center',
		[Breakpoints.maxWidth('md')]: {
			textAlign: 'left'
		}
	},
	tag: {
		padding: '10px 23px',
		borderRadius: '3px',
		fontWeight: '500',
		whiteSpace: 'nowrap',
		fontSize: '14px'
	},
	open: {
		background: 'rgba(0,107,53,0.1)',
		color: Colors.veryDarkCyan
	},
	processing: {
		background: 'rgba(246,139,31,0.1)',
		color: Colors.vividOrange
	},
	closed: {
		background: 'rgba(0,84,166,0.1)',
		color: Colors.secondary
	},
	actionButtons: {
		[Breakpoints.minWidth('xs')]: {
			'& button': {
				':last-child': {
					marginLeft: '5px'
				}
			}
		},
		[Breakpoints.maxWidth('xs')]: {
			'& button': {
				':last-child': {
					marginTop: '5px'
				}
			}
		},
		[Breakpoints.widthBetween('xxl', 'sidebarlg')]: {
			'& button': {
				':last-child': {
					marginTop: '5px'
				}
			}
		}
	},
	informationLine: {
		display: 'flex',
		MsFlexWrap: 'wrap',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px'
	},
	infoQuestion: {
		display: 'block',
		position: 'relative',
		width: '100%',
		paddingRight: '15px',
		paddingLeft: '15px',
		[Breakpoints.minWidth('xxl')]: {
			flex: '0 0 33.333333%',
			maxWidth: '33.333333%'
		},
		[Breakpoints.minWidth('xl')]: {
			flex: '0 0 50%',
			maxWidth: '50%'
		},
		[Breakpoints.minWidth('md')]: {
			flex: '0 0 33.333333%',
			maxWidth: '33.333333%'
		},
		[Breakpoints.minWidth('sm')]: {
			flex: '0 0 50%',
			maxWidth: '50%'
		}
	},
	infoTooltip: {
		display: 'inline-flex',
		verticalAlign: 'middle',
		marginLeft: '5px',
		'& span': {
			maxWidth: '15px'
		}
	},
	infoAnswer: {
		display: 'block',
		position: 'relative',
		width: '100%',
		paddingRight: '15px',
		paddingLeft: '15px',
		[Breakpoints.minWidth('xxl')]: {
			flex: '0 0 33.333333%',
			maxWidth: '33.333333%'
		},
		[Breakpoints.minWidth('xl')]: {
			flex: '0 0 50%',
			maxWidth: '50%'
		},
		[Breakpoints.minWidth('md')]: {
			flex: '0 0 33.333333%',
			maxWidth: '33.333333%'
		},
		[Breakpoints.minWidth('sm')]: {
			flex: '0 0 50%',
			maxWidth: '50%'
		}
	},
	paragraph: {
		margin: '0px 0px 15px',
		padding: '0px',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '25px',
		'& a, a:visited': {
			textDecoration: 'none',
			color: Colors.secondary,
			':hover': {
				textDecoration: 'underline'
			}
		}
	},
	h3: {
		fontWeight: '700',
		color: Colors.primary,
		margin: '0px 0px 15px',
		padding: '0px',
		fontSize: '25px',
		lineHeight: '28px'
	},
	h4: {
		fontWeight: '600',
		marginBottom: '20px',
		margin: '20px 0px 5px',
		padding: '0px',
		fontSize: '20px',
		lineHeight: '22px',
		color: Colors.lightBlack
	},
	actionTitle: {
		display: 'flex',
		gap: '15px',
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginBottom: '1rem'
	},
	button: {
		padding: '10px 20px',
		fontSize: '13px',
		display: 'inline-block',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		background: Colors.secondary,
		cursor: 'pointer',
		border: `1px solid ${Colors.secondary}`,
		':hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2
		}
	},
	clearInnerPadding: {
		'& section': {
			padding: '0px',
			margin: '0px'
		},
		'& .tooltip-content': {
			left: 'auto !important',
			right: '0 !important'
		}
	},
	historyCard: {
		'& > div': {
			padding: '0px !important',
			width: '100% !important'
		},
		[Breakpoints.maxWidth('sm')]: {
			'& .card-content': {
				padding: '0px !important'
			},
			'& .tns-item': {
				margin: '0px !important'
			}
		}
	},
	buttonWithIcon: {
		whiteSpace: 'nowrap',
		'& img': {
			width: '12px',
			marginRight: '5px',
			verticalAlign: 'top'
		}
	},
	attachments: {
		marginTop: '0px !important',
		'& > div': {
			paddingTop: '5px',
			paddingBottom: '5px',
			marginBottom: '15px !important'
		}
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	notificationText: {
		[Breakpoints.maxWidth('xs')]: {
			display: 'flex !important'
		},
		'& img': {
			width: '20px',
			margin: '0px .5rem',
			display: 'inline-block',
			verticalAlign: 'middle'
		}
	},
	table: {
		border: '1px solid #ccc',
		borderCollapse: 'collapse',
		width: '100%',
		'& tr': {
			borderTop: '1px solid #ccc',
			'& td': {
				textAlign: 'right',
				padding: '8px 16px',
				fontSize: '14px',
				':first-child': {
					textAlign: 'left',
					fontWeight: 'bold',
					width: '50%'
				}
			}
		},
		'& tr.total': {
			fontWeight: 'bold',
			fontSize: '18px',
			background: '#e6e7e8',
			td: {
				fontSize: '16px !important'
			}
		}
	},
	buttonContainer: {
		marginTop: '1rem!important'
	},
	noMargin: {
		marginLeft: '0px',
		marginRight: '0px'
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400',
		'& a': {
			color: Colors.secondary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	},
	messageContainer: {
		width: '100%',
		paddingRight: '30px',
		[Breakpoints.maxWidth('lg')]: {
			marginLeft: '-15px',
			paddingRight: '0px'
		},
		[Breakpoints.maxWidth('md')]: {
			marginLeft: '0px',
			paddingRight: '0px'
		}
		// background: 'red',
	},
	removePadding: {
		'& .css-has3ai': {
			[Breakpoints.maxWidth('md')]: {
				paddingLeft: '0px !important'
			}
		}
	}
})
