import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { formatStrapiText } from '@utils/methods'
import { SelectList } from '@utils/request'
import React, { FC } from 'react'
import * as pageUtils from './__details.utils'

const ReplacementOilHeatingSystemDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value)?.label
	}

	const homeTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_homeType_singleFamily,
			value: REQUEST_CONFIGS_KEYS.homeType.singleFamily,
			key: 'select_homeType_singleFamily'
		},
		{
			label: pageAssets?.select_homeType_twinned,
			value: REQUEST_CONFIGS_KEYS.homeType.twinned,
			key: 'select_homeType_twinned'
		},
		{
			label: pageAssets?.select_homeType_duplex,
			value: REQUEST_CONFIGS_KEYS.homeType.duplex,
			key: 'select_homeType_duplex'
		},
		{
			label: pageAssets?.select_homeType_mobile,
			value: REQUEST_CONFIGS_KEYS.homeType.mobile,
			key: 'select_homeType_mobile'
		},
		{
			label: pageAssets?.select_homeType_triplex,
			value: REQUEST_CONFIGS_KEYS.homeType.triplex,
			key: 'select_homeType_triplex'
		},
		{
			label: pageAssets?.select_homeType_in_a_row,
			value: REQUEST_CONFIGS_KEYS.homeType.inRows,
			key: 'select_homeType_in_a_row'
		},
		{
			label: pageAssets?.select_homeType_building,
			value: REQUEST_CONFIGS_KEYS.homeType.building,
			key: 'select_homeType_building'
		}
	]

	const systemTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_systemType_oil_system,
			value: REQUEST_CONFIGS_KEYS.systemType.oil,
			key: 'select_systemType_oil_system'
		},
		{
			label: pageAssets?.select_systemType_dualEnergy_system,
			value: REQUEST_CONFIGS_KEYS.systemType.biEnergetic,
			key: 'select_systemType_dualEnergy_system'
		}
	]

	const alternativeHeatingSystemList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_electric,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.electric,
			key: 'select_alternativeHeatingSystem_electric'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_aerothermal,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.aerothermal,
			key: 'select_alternativeHeatingSystem_aerothermal'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_solar,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.solar,
			key: 'select_alternativeHeatingSystem_solar'
		},
		{
			label: pageAssets?.select_alternativeHeatingSystem_geothermal,
			value: REQUEST_CONFIGS_KEYS.alternativeHeatingSystem.geothermal,
			key: 'select_alternativeHeatingSystem_geothermal'
		}
	]

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{formatStrapiText(
					pageAssets?.request_from_replacementOilHeatingSystem_first_subtitle
				)}
			</h4>

			{request?.incidentForm?.accommodationType && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{formatStrapiText(
							pageAssets?.request_form_replacementOilHeatingSystem_accommodationType_label
						)}{' '}
					</b>
					<br />
					{getLabelFromList(
						homeTypeList,
						request?.incidentForm?.accommodationType
					)}
				</p>
			)}

			{request?.incidentForm?.numberOfAccommodationConverted && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{formatStrapiText(
							pageAssets?.request_form_replacementOilHeatingSystem_numberOfAccommodationConverted_label
						)}
					</b>
					<br />
					{request?.incidentForm?.numberOfAccommodationConverted}
				</p>
			)}

			{request?.incidentForm?.convertedAccommodationList && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{' '}
						{formatStrapiText(
							pageAssets?.request_form_replacementOilHeatingSystem_convertedAccommodationList_label
						)}{' '}
					</b>
					<br />
					{request?.incidentForm?.convertedAccommodationList}
				</p>
			)}

			<h4 className={pageUtils.classes.h4}>
				{formatStrapiText(
					pageAssets?.request_from_replacementOilHeatingSystem_second_subtitle
				)}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{formatStrapiText(
						pageAssets?.request_form_replacementOilHeatingSystem_fuelSystemReplacedType_label
					)}
				</b>
				<br />
				{getLabelFromList(
					systemTypeList,
					request?.incidentForm?.fuelSystemReplacedType
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{formatStrapiText(
						pageAssets?.request_form_replacementOilHeatingSystem_heatingReplacementSystemType_label
					)}
				</b>
				<br />
				{getLabelFromList(
					alternativeHeatingSystemList,
					request?.incidentForm?.heatingReplacementSystemType
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{formatStrapiText(
						pageAssets?.request_form_replacementOilHeatingSystem_contactorLicenseNumber_label
					)}
				</b>
				<br />
				{request?.incidentForm?.contactorLicenseNumber}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{formatStrapiText(
						pageAssets?.request_form_replacementOilHeatingSystem_subventionTotalValueRequested_label
					)}
				</b>
				<br />
				{request?.incidentForm?.subventionTotalValueRequested}
			</p>
		</>
	)
}

export default ReplacementOilHeatingSystemDetails
