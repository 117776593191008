import React, { FC } from 'react'
import * as pageUtils from './__details.utils'

const FireplaceGrantFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const getTimeFormatted = (value) => {
		let date = value?.split('T', 2)
		if (date) {
			return date[0]
		}

		return ''
	}
	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_fireplaceGrant_firstSubStep_title}
			</h4>
			<h5 className={pageUtils.classes.h5}>
				{pageAssets?.request_form_fireplaceGrant_replaced_device_title}
			</h5>
			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_fireplaceGrant_installationYear_label}</b>
				<br />

				{request.incidentForm?.installationYear}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets?.label_brand}</b>
				<br />
				{request.incidentForm?.oldDeviceMake}
			</p>

			{request.incidentForm?.oldDeviceModel && (
				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets?.label_model}</b>
					<br />
					{request.incidentForm?.oldDeviceModel}
				</p>
			)}

			<h5 className={pageUtils.classes.h5}>
				{pageAssets?.request_form_fireplaceGrant_new_device_title}
			</h5>
			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets?.request_form_fireplaceGrant_installationDateNewDevice_label
					}
				</b>
				<br />
				{getTimeFormatted(request.incidentForm?.installationDate)}
			</p>

			{request.incidentForm?.newDeviceMake && (
				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets?.label_brand}</b>
					<br />
					{request.incidentForm?.newDeviceMake}
				</p>
			)}

			{request.incidentForm?.newDeviceModel && (
				<p className={pageUtils.classes.paragraph}>
					<b>{pageAssets?.label_model}</b>
					<br />
					{request.incidentForm?.newDeviceModel}
				</p>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets?.request_form_fireplaceGrant_installationCosts_label}</b>
				<br />
				{request.incidentForm?.installationCosts}
			</p>
		</>
	)
}

export default FireplaceGrantFormDetails
