import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { formatStrapiText } from '@utils/methods'

const PublicAuctionsFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	return (
		<>
			<h5 className={pageUtils.classes.h5}>
				{pageAssets.request_form_billing_adresse}
			</h5>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{pageAssets.request_form_publicAuctions_isSameBillingAdresse_label}
				</b>
				<br />
				{request?.incidentForm?.isSameBillingAdresse == true
					? pageAssets?.request_form_select_yes
					: pageAssets?.request_form_select_no}
			</p>

			{request.incidentForm?.isSameBillingAdresse == false && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{request.incidentForm?.concernedInfo?.last_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_address} </b>
						<br />
						{request.incidentForm?.concernedInfo?.address}
					</p>

					{request.incidentForm?.concernedInfo?.appartment && (
						<p className={pageUtils.classes.paragraph}>
							<b> {formatStrapiText(pageAssets?.label_apartment_office)} </b>
							<br />
							{request.incidentForm?.concernedInfo?.appartment}
						</p>
					)}

					{request.incidentForm?.concernedInfo?.city && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_city} </b>
							<br />
							{request.incidentForm?.concernedInfo?.city}
						</p>
					)}

					{request.incidentForm?.concernedInfo?.postal_code && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_postalCode} </b>
							<br />
							{request.incidentForm?.concernedInfo?.postal_code}
						</p>
					)}
				</>
			)}
		</>
	)
}

export default PublicAuctionsFormDetails
