import React, { FC, RefObject, useContext, useRef, useState } from 'react'
import Modal from '@components/ui/modal'
import Notification from '@components/ui/notification'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { SelectList } from '@utils/request'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { RequestConsent } from '@services/models'
import { TeamMember } from '@services/models/_miscellaneous.model'
import Loader from '../loader'

type Props = {
	isModalOpen: boolean
	onSetIsModalOpen: (isOpen: boolean) => void
	onAddNewConsent: (consent: RequestConsent) => void
}

const ConsentForm: FC<Props> = ({
	isModalOpen,
	onSetIsModalOpen,
	onAddNewConsent
}) => {
	const { pageData, authUser, userRequest, electedTeamMembers } =
		useContext<AppContextProps>(AppStateContext)

	const selectRef: RefObject<HTMLSelectElement> =
		useRef<HTMLSelectElement>(null)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<boolean>(false)
	const [elected, setElected] = useState<TeamMember>()
	const listElected: SelectList[] = [
		{
			label: '',
			value: '',
			key: 'noSelection'
		} as SelectList,
		...(electedTeamMembers?.map(({ name: label, id }: TeamMember) => ({
			label,
			value: label,
			key: id
		})) || [])
	] as SelectList[]

	const onSelectElected = (name: string, id: string) => {
		setElected({
			name,
			id
		} as TeamMember)
	}

	const onSubmit = async () => {
		if (authUser && userRequest) {
			if (elected) {
				setIsLoading(true)
				const consent = new RequestConsent(authUser)

				consent.electedTeamMember = elected
				consent.request = userRequest

				const consentId = await userRequest.addConsent(consent)
				consent.id = consentId

				// reload page TODO: Update later for redux toolkit
				window.location.reload()

				// TODO: fire onAddNewConsent
				// onAddNewConsent(new RequestConsent())
				// created post and save item to the requests
				// and close the modal, add load icon in modal
				//
				return
			}

			setHasError(true)

			selectRef.current?.focus()
		}
	}

	const onCancelCreation = () => {
		setElected(undefined)
		setHasError(false)
	}

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				title={pageData?.assets?.my_request_consent_add_consent}
				showExitBtn
				onSetIsOpen={onSetIsModalOpen}
				primaryButtonText={pageData?.assets?.add}
				cancelButtonText={pageData?.assets?.cancel}
				onPrimaryButton={onSubmit}
				onCancelBtn={onCancelCreation}
				disabled={isLoading}
			>
				<>
					{isLoading ? (
						<Loader text={pageData?.assets?.loading} />
					) : (
						<>
							<Notification
								type="warning"
								text={pageData?.assets?.my_request_consent_warning_text}
							/>
							<SelectInput
								id="listElected"
								label={
									pageData?.assets?.my_request_consent_select_an_elected_label
								}
								required
								hasError={hasError}
								list={listElected}
								value={`${elected?.name}`}
								onChange={onSelectElected}
								ref={selectRef}
							/>
						</>
					)}
				</>
			</Modal>
		</>
	)
}

export default ConsentForm
