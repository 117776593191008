import React, { FC, useState } from 'react'
import InfoModal from '@components/ui/historyCard/infoModal'
import TimelineBlock, { ORIGIN } from '@components/ui/historyCard/timelineBlock'
import DesktopView from '@components/ui/historyCard/desktopView'
import MobileView from '@components/ui/historyCard/mobileView'
import { ITimeline } from '@services/types'
import * as pageUtils from '@components/ui/historyCard/__index.utils'
import { CustomDate } from '@services/models'

type historyCardProps = {
	history?: ITimeline[] | undefined
	requestCreationDate?:  CustomDate
}

const HistoryCard: FC<historyCardProps> = ({ history, requestCreationDate }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [modalCommunication, setModalCommunication] = useState<ITimeline>()
	const [origin, setOrigin] = useState<ORIGIN>()

	return (
		<>
			<MobileView>
				<TimelineBlock
					setOrigin={setOrigin}
					history={history}
					onOpenModal={setIsModalOpen}
					setModalCommunication={setModalCommunication}
					classWrapper={pageUtils.classes.mobileView}
					requestCreationDate={requestCreationDate}
				/>
			</MobileView>
			<DesktopView>
				<TimelineBlock
					setOrigin={setOrigin}
					history={history}
					onOpenModal={setIsModalOpen}
					setModalCommunication={setModalCommunication}
					classWrapper={pageUtils.classes.desktopView}
					requestCreationDate={requestCreationDate}
				/>
			</DesktopView>
			<InfoModal
				isModalOpen={isModalOpen}
				communication={modalCommunication}
				origin={origin!}
				close={() => {
					setIsModalOpen(false), setModalCommunication(undefined)
				}}
			/>
		</>
	)
}

export default HistoryCard
