import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'

const OilHeatingDeclarationFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const convertedHeatingSystemTypeList: RadioList[] = [
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_firstOption,
			value: REQUEST_CONFIGS_KEYS.convertedHeatingSystemType.firstOption,
			key: 'select_oilHeatingDeclaration_convertedHeatingSystemType_firstOption'
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_secondOption,
			value: REQUEST_CONFIGS_KEYS.convertedHeatingSystemType.secondOption,
			key: 'select_oilHeatingDeclaration_convertedHeatingSystemType_secondOption'
		}
	]

	const heatingEnergySourceList: RadioList[] = [
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_firstOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.firstOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_firstOption'
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_secondOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.secondOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_secondOption'
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_thirdOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.thirdOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_thirdOption'
		}
	]

	const mainHeatingSystemTypeList: RadioList[] = [
		{
			// Air pulsé
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_firstOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.firstOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_firstOption'
		},
		{
			// Convecteur
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_secondOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.secondOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_secondOption'
		},
		{
			// Eau Chaude
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_thirdOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.thirdOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_thirdOption'
		},
		{
			// Géothermie
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fourthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.fourthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_fourthOption'
		},
		{
			// Plinthes
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fifthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.fifthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_fifthOption'
		},
		{
			// Radiant
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_sixthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.sixthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_sixthOption'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_declaration_adresse}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_isSameAdresse_label}</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.buildingAddressSameHasApplicant
				)}
			</p>

			{/* isSameDeclarationAdresse */}
			{request.incidentForm?.buildingAddressSameHasApplicant == false && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_address} </b>
						<br />
						{request.incidentForm?.concernedInfo?.address}
					</p>

					{request.incidentForm?.concernedInfo?.appartment && (
						<p className={pageUtils.classes.paragraph}>
							<b> {formatStrapiText(pageAssets?.label_apartment_office)} </b>
							<br />
							{request.incidentForm?.concernedInfo?.appartment}
						</p>
					)}

					{request.incidentForm?.concernedInfo?.city && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_city} </b>
							<br />
							{request.incidentForm?.concernedInfo?.city}
						</p>
					)}

					{request.incidentForm?.concernedInfo?.postalCode && (
						<p className={pageUtils.classes.paragraph}>
							<b> {pageAssets.label_postalCode} </b>
							<br />
							{request.incidentForm?.concernedInfo?.postalCode}
						</p>
					)}
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{pageAssets.request_form_oilHeatingDeclaration_isBuildingOwner_label}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isBuildingOwner
				)}
			</p>

			{request.incidentForm?.isBuildingOwner == false && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{pageAssets.request_form_oilHeatingDeclaration_ownerFullName_label}
					</b>
					<br />
					{request.incidentForm?.ownerFullName}
				</p>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_oilHeatingDeclaration_secondSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_oilHeatingDeclaration_isDeclarationForHeatingReplacement_label
					}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isDeclarationForHeatingReplacement
				)}
			</p>

			{request.incidentForm?.isDeclarationForHeatingReplacement == true && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_oilHeatingDeclaration_installationDateInput_label
						}
					</b>
					<br />
					{pageUtils.getLocaleDate(request.incidentForm?.installationDate!)}
				</p>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_oilHeatingDeclaration_isOilHeatingSystem_label
					}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isOilHeatingSystem
				)}
			</p>

			{request.incidentForm?.isOilHeatingSystem == true && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_oilHeatingDeclaration_isDualEnergySystem_label
							}
						</b>
						<br />
						{getLabelFromList(
							radioListYesNo,
							request.incidentForm?.isDualEnergySystem
						)}
					</p>

					{request.incidentForm?.isDualEnergySystem == true && (
						<p className={pageUtils.classes.paragraph}>
							<b>
								{
									pageAssets.request_form_oilHeatingDeclaration_convertedHeatingSystemType_label
								}
							</b>
							<br />
							{getLabelFromList(
								convertedHeatingSystemTypeList,
								request.incidentForm?.convertedHeatingSystemType
							)}
						</p>
					)}
				</>
			)}

			{request.incidentForm?.isOilHeatingSystem == false && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_oilHeatingDeclaration_heatingEnergySource_label
							}
						</b>
						<br />
						{getLabelFromList(
							heatingEnergySourceList,
							request.incidentForm?.heatingEnergySource
						)}
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_oilHeatingDeclaration_mainHeatingSystemType_label
							}
						</b>
						<br />
						{getLabelFromList(
							mainHeatingSystemTypeList,
							request.incidentForm?.mainHeatingSystemType
						)}
					</p>
				</>
			)}

			{request.incidentForm?.isDeclarationForHeatingReplacement == false && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_oilHeatingDeclaration_approximateInstallYear_label
						}
					</b>
					<br />
					{request.incidentForm?.approximateInstallYear}
				</p>
			)}
		</>
	)
}

export default OilHeatingDeclarationFormDetails
