import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { ParkingPermitInformation, RadioList, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { capitalize, formatStrapiText } from '@utils/methods'
import ParkingPermitModal from '../modals/parkingPermitModal'
import Text from '../text'
import { stationnementIcon } from '@images/icons'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'

type Classes = {
	headerContainer: string
	card: string
	permitWrapper: string
	imageIcon: string
	buttonWrapper: string
	linkButton: string
	underlineOnHover: string
	verticalDisplay: string
}

const classes: Classes = makeClasses({
	headerContainer: {
		marginBottom: '10px',
		display: 'flex',
		gap: '20px',
		'& h5': {
			margin: `30px 0 15px 0`
		}
	},
	card: {
		background: Colors.darkWhite,
		marginBottom: '20px',
		display: 'flex',
		justifyContent: 'space-between',
		// cursor: 'pointer',
		alignItems: 'center',
		border: `3px solid ${Colors.darkWhite}`,
		transition: '0.2s',
		padding: '36px 30px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: '20px',
			'& .css-r1u628 ': {
				marginLeft: '0px ',
				marginRight: '30px ',
				marginTop: '10px'
			}
		},
		[Breakpoints.widthBetween('xs', 'lg')]: {
			flexDirection: 'row !important',
			justifyContent: 'space-between !important'
		},
		[Breakpoints.maxWidth('sidebar')]: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		}
	},
	permitWrapper: {
		display: 'flex',
		alignItems: 'center',
		'& img': {
			marginRight: '10px'
		}
	},
	imageIcon: {
		width: '45px',
		verticalAlign: 'middle'
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('lg')]: {
			'& button:nth-child(1)': {
				marginRight: '15px !important'
			},
			'& button:nth-child(2)': {
				marginRight: '0px !important'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			flexDirection: 'column',
			alignItems: 'start'
		},
		[Breakpoints.maxWidth('sidebar')]: {
			'& .button': {
				marginLeft: '0px !important',
				[Breakpoints.maxWidth(321)]: {
					padding: '0px !important'
				}
			}
		}
	},
	linkButton: {
		backgroundColor: 'transparent',
		border: `none`,
		color: `${Colors.secondary}`,
		'&:hover': {
			backgroundColor: 'transparent !important',
			border: `none !important`,
			color: `${Colors.secondary} !important`
		}
	},
	underlineOnHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	}
})

const ParkingPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const { authUser } = requestForm()

	const [modalParkingInformation, setModalParkingInformation] =
		useState<ParkingPermitInformation>()
	const [currentAnimalIndex, setCurrentAnimalIndex] = useState<number>(0)
	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]
	const vignetteNumber = {
		0: pageAssets?.request_form_parkingPermit_second,
		1: pageAssets?.request_form_parkingPermit_third,
		2: pageAssets?.request_form_parkingPermit_fouth
	}

	const radioListSpecialPermit: RadioList[] = [
		{
			label: pageAssets?.label_i_want_to_buy,
			value: REQUEST_CONFIGS_KEYS.transactionReason.firstParking,
			key: 'label_i_want_to_buy'
		},
		{
			label: pageAssets?.label_i_want_to_renew_a_parking_permit,
			value: REQUEST_CONFIGS_KEYS.transactionReason.renewParking,
			key: 'label_i_want_to_renew_a_parking_permit'
		},
		{
			label: pageAssets?.label_I_want_to_replace_a_parking_permit,
			value: REQUEST_CONFIGS_KEYS.transactionReason.replaceMySticker,
			key: 'label_I_want_to_replace_a_parking_permit'
		}
	]

	const reasonForReplacementList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_parkingPermit_broken_or_destroyed,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.broken,
			key: 'request_form_parkingPermit_broken_or_destroyed'
		},
		{
			label: pageAssets?.request_form_parkingPermit_change_of_verhicle,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.changeOfVehicle,
			key: 'request_form_parkingPermit_change_of_verhicle'
		},
		{
			label: pageAssets?.request_form_parkingPermit_data_correction,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.dataCorrection,
			key: 'request_form_parkingPermit_data_correction'
		},
		{
			label: pageAssets?.request_form_parkingPermit_moving,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.moving,
			key: 'request_form_parkingPermit_moving'
		},
		{
			label:
				pageAssets?.request_form_parkingPermit_moving_and_changing_vehicles,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.movingVehicles,
			key: 'request_form_parkingPermit_moving_and_changing_vehicles'
		},
		{
			label: pageAssets?.request_form_parkingPermit_lost_vignette,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.lostthumbnail,
			key: 'request_form_parkingPermit_lost_vignette'
		},
		{
			label: pageAssets?.request_form_parkingPermit_stolen_vignette,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.stolenThumbnail,
			key: 'request_form_parkingPermit_stolen_vignette'
		},
		{
			label: pageAssets?.request_form_parkingPermit_other_reason,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.otherReason,
			key: 'request_form_parkingPermit_other_reason'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}
	const getSubtitle = (vignetteNumber: string): string => {
		return `${pageAssets?.label_information_about} ${vignetteNumber || ''} ${
			pageAssets?.label_vignette
		}`
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<>
					<div>
						<strong>{question}</strong>
						<br />
						<span>{answer}</span>
					</div>
					<br />
				</>
			)
		}
	}
	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_parkingPermit_address_of_request}
			</h4>
			{questionAnswer(
				pageAssets?.request_form_isSameAdresse_label,
				getLabelFromList(
					radioListYesNo,
					request.incidentForm?.sameAddressAsApplicant
				)
			)}

			{questionAnswer(
				pageAssets?.label_address,
				request.incidentForm?.demandAddress
			)}

			{questionAnswer(
				formatStrapiText(pageAssets?.label_apartment_office),
				request.incidentForm?.demandAppartment 
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.label_vehicle_information}
			</h4>

			{questionAnswer(
				pageAssets?.label_what_do_you_want_to_do,
				getLabelFromList(
					radioListSpecialPermit,
					request.incidentForm?.transactionReason
				)
			)}

			{request.incidentForm?.transactionReason?.toString() !=
				radioListSpecialPermit[1].value && (
					<>
					{questionAnswer(
						pageAssets?.label_what_is_the_reason_for_replacement,
						getLabelFromList(
							reasonForReplacementList,
							request.incidentForm?.replacingReason
						)
					)}

					{questionAnswer(
						pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label,
						request.incidentForm?.replacingReasonOther
					)}

					{questionAnswer(
						pageAssets?.label_vignette_number,
						request.incidentForm?.parkingPermitList?.at(0)
							?.renewal_sticker_number
					)}

					{questionAnswer(
						pageAssets?.label_name_of_vignette_owner,
						request.incidentForm?.parkingPermitList?.at(0)
							?.renewal_sticker_owner_name
					)}

					{questionAnswer(
						pageAssets?.label_license_plate_number,
						request.incidentForm?.parkingPermitList?.at(0)?.vehicle_plate_number
					)}

					{questionAnswer(
						pageAssets?.label_brand,
						request.incidentForm?.parkingPermitList?.at(0)?.vehicle_brand
					)}

					{questionAnswer(
						pageAssets?.label_model,
						request.incidentForm?.parkingPermitList?.at(0)?.vehicle_model
					)}

					{questionAnswer(
						pageAssets?.label_year,
						request.incidentForm?.parkingPermitList?.at(0)?.vehicle_year
					)}

					{/* {questionAnswer(
						pageAssets?.request_form_is_car_owner,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.parkingPermitList?.at(0)?.is_vehicle_owner
						)
					)} */}

					{questionAnswer(
						request.incidentForm?.transactionReason ==
							radioListSpecialPermit[0].value
							? pageAssets?.request_form_is_car_owner
							: pageAssets?.label_is_owner_of_vignette_owner_of_property,
						getLabelFromList(
							radioListYesNo,
							request.incidentForm?.parkingPermitList
								?.at(0)
								?.vehicle_owner?.toString() ?? radioListYesNo[1].value
						)
					)}
				</>
			)}

			{request.incidentForm?.transactionReason ==
				radioListSpecialPermit[1].value && (
				<>
					{request.incidentForm?.parkingPermitList?.map(
						(parkingInformation, row) => (
							<>
								<div className={classes.headerContainer}>
									<h5 className={pageUtils.classes.h5}>
										{
											<Text
												content={
													request.incidentForm?.transactionReason ==
														radioListSpecialPermit[1].value &&
													request.incidentForm?.parkingPermitList &&
													request.incidentForm?.parkingPermitList.length > 1
														? getSubtitle(vignetteNumber[row - 1])
														: pageAssets?.label_Information_about_the_vehicle
												}
											/>
										}
									</h5>
								</div>
								<div className={classes.card}>
									<div className={classes.permitWrapper}>
										<img
											src={stationnementIcon}
											alt="animal"
											className={classes.imageIcon}
										/>
										<div>
											<strong>
												{capitalize(pageAssets?.label_vignette)}{' '}
												{parkingInformation?.renewal_sticker_number}
											</strong>
											<p style={{ marginTop: '5px' }}>
												{parkingInformation?.vehicle_brand}{' '}
												{parkingInformation?.vehicle_model}{' '}
												{parkingInformation?.vehicle_year}
											</p>
										</div>
									</div>

									<div className={classes.buttonWrapper}>
										<>
											<button
												className={joinClasses([
													pageUtils.classes.btn,
													pageUtils.classes.button,
													classes.linkButton,
													classes.underlineOnHover,
													'button'
												])}
												onClick={() => {
													setModalParkingInformation(parkingInformation)
													setOpenDetailModal(true)
													setCurrentAnimalIndex(row)
												}}
												type="button"
											>
												{formatStrapiText(pageAssets?.label_see_details)}
											</button>
										</>
									</div>
								</div>
							</>
						)
					)}

					<ParkingPermitModal
						isModalOpen={openDetailModal}
						parkingInformation={modalParkingInformation}
						primaryButtonText={pageAssets?.button_complete}
						close={() => {
							setOpenDetailModal(false)
						}}
						onPrimaryButton={() => {
							setOpenDetailModal(false)
						}}
						isConsultationPage
					/>
				</>
			)}
		</>
	)
}

export default ParkingPermitFormDetails
