import React, { FC, useContext } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Text from '@components/ui/text'
import { pdfIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { ICommentsAttachment } from '@services/types'
import { formatBytes } from './__index.utils'

type propsTypes = {
	files: ICommentsAttachment[]
	showHeader?: boolean
	noTitle?: boolean
	classWrapper?: string
}

type ClassType = {
	text: string
	files: string
	last: string
	name: string
	img: string
	date: string
	container: string
}

const classes: ClassType = makeClasses({
	files: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '15px',
		background: Colors.ligthGrey2,
		borderBottom: `1px solid ${Colors.greyIron}`,
		marginBottom: '0px',
		':last-child': {
			borderBottom: `none`,
			marginBottom: '30px'
		},
		'& img': {
			width: '100%',
			verticalAlign: 'middle',
			marginRight: '10px',
			borderStyle: 'none'
		},
		'& a': {
			textDecoration: 'none',
			color: Colors.secondary,
			background: 'transparent',
			':visited': {
				textDecoration: 'none',
				color: Colors.secondary
			},
			':hover': {
				textDecoration: 'underline'
			}
		}
	},
	name: {
		color: Colors.secondary,
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		'& < div': {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden'
		}
	},
	img: {
		display: 'flex',
		alignItems: 'center',
		width: '40px',
		height: '40px',
		background: Colors.white,
		border: `1px solid ${Colors.white}`,
		marginRight: '15px'
	},
	date: {
		fontWeight: 500,
		color: Colors.darkGrey,
		fontSize: '14px',
		whiteSpace: 'nowrap',
		paddingLeft: '10px',
		[Breakpoints.maxWidth('sm')]: {
			display: 'block !important'
		},
		'& p': {
			margin: '0px'
		},
		'& span': {
			[Breakpoints.widthBetween('lg', 'xl')]: {
				display: 'none'
			}
		},
		'& div': {
			[Breakpoints.widthBetween('lg', 'xl')]: {
				display: 'block',
				lineHeight: '16px'
			},
			display: 'none'
		}
	},
	last: {
		borderBottom: 'none'
	},
	text: {
		margin: '0 0 15px',
		padding: 0,
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '25px'
	},
	container: {
		marginTop: '20px'
	}
})

const Attachments: FC<propsTypes> = ({
	showHeader,
	files,
	noTitle,
	classWrapper
}) => {
	const { pageData } = useContext(AppStateContext)

	return (
		<div className={joinClasses([classes.container, classWrapper || ''])}>
			{!noTitle && (
				<>
					<p className={classes.text}>
						<b>{pageData?.assets?.page_requestInfo_enclosed}</b>
					</p>
					{!files?.length && (
						<div>
							<Text content={pageData?.assets?.label_no_attachments} />
						</div>
					)}
				</>
			)}
			{!!files?.length &&
				files.map((file, index) => (
					<div
						key={index}
						className={joinClasses([
							classes.files,
							files?.length === index + 1 ? classes.last : ''
						])}
					>
						<div className={classes.name}>
							<div
								className={classes.img}
								style={
									file.mimeType.includes('pdf')
										? { textAlign: 'center', lineHeight: '40px' }
										: {}
								}
							>
								<a
									href={`data:${file.mimeType};base64,${file.fileContent}`}
									target="_blank"
								>
									<img
										src={
											file.mimeType.includes('pdf')
												? pdfIcon
												: `data:${file.mimeType};base64,${file.fileContent}`
										}
										style={
											file.mimeType.includes('pdf') ? { width: '25px' } : {}
										}
									/>
								</a>
							</div>
							<div>
								<a
									href={`data:${file.mimeType};base64,${file.fileContent}`}
									download={file?.fileName}
								>
									{file?.fileName}
								</a>
								<span style={{ color: Colors.darkGrey }}>{` (${formatBytes(
									file.fileSize
								)})`}</span>
							</div>
						</div>
						{showHeader && (
							<div className={classes.date}>
								<span>{file?.dateAdd?.localDateAndTimeString}</span>
								<div>
									<Text
										content={file?.dateAdd?.localDateAndTimeString
											.split(' ')
											.join('<br />')}
									/>
								</div>
							</div>
						)}
					</div>
				))}
		</div>
	)
}

export default Attachments
