import React, { FC } from 'react'
import * as pageUtils from './__details.utils'

const RainwaterBarrelDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const barrel2Exist =
		request.incidentForm?.manufacturerName2 &&
		request.incidentForm?.nameAndModelNumber2 &&
		request.incidentForm?.costBeforeTax2

	const barrel3Exist =
		request.incidentForm?.manufacturerName3 &&
		request.incidentForm?.nameAndModelNumber3 &&
		request.incidentForm?.costBeforeTax3

	return (
		<>
			{request.location?.address && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_address_of_the_building}</b>
						<span>{request.location?.address}</span>
						<br />
						<span>{request.location?.city} </span>

						<span> {request.location?.postalCode}</span>
					</p>
				</>
			)}
			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_rainwaterBarrel_numberOfBarrel_label} </b>
				<br />
				{barrel2Exist ? '2' : '1'}
			</p>

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.rain_waterBarrel_info_on_first_barrel}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{pageAssets.request_form_rainwaterBarrel_manufacturerName1_label}{' '}
				</b>
				<br />
				{request.incidentForm?.manufacturerName1}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_rainwaterBarrel_nameAndModelNumber1_label
					}{' '}
				</b>
				<br />
				{request.incidentForm?.nameAndModelNumber1}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_rainwaterBarrel_costBeforeTax1_label} </b>
				<br />
				{request.incidentForm?.costBeforeTax1}
			</p>

			{barrel2Exist && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets.rain_waterBarrel_info_on_second_barrel}
					</h4>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_manufacturerName2_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.manufacturerName2}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_nameAndModelNumber2_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.nameAndModelNumber2}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_costBeforeTax2_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.costBeforeTax2}
					</p>
				</>
			)}

			{barrel3Exist && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets.rain_waterBarrel_info_on_third_barrel}
					</h4>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_manufacturerName3_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.manufacturerName3}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_nameAndModelNumber3_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.nameAndModelNumber3}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_rainwaterBarrel_costBeforeTax3_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.costBeforeTax3}
					</p>
				</>
			)}
		</>
	)
}

export default RainwaterBarrelDetails
