import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import {
	getListfromString,
	useTypeListAndValues
} from '@components/requestForms/__forms.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'

const HistoricalArchiveFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioIsOrganizationRequest: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioRequiresTechnicalSpecs: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioIsInPersonAppointment: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioAreDocumentsDiffused: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioIsLicenceRecipientApplicant: RadioList[] = [
		{
			label:
				pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.licenceAttribution.myName,
			key: 'request_form_historicalArchive_isLicenceRecipientApplicant_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson,
			key: 'request_form_historicalArchive_isLicenceRecipientApplicant_secondOption_label'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value.toString())?.label
	}

	const getAppointmentTime = (value) => {
		let date = value?.split('T', 2)
		let time = date[1].split('-', 2)
		if (date) {
			return time[0]
		}

		return ''
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_historicalArchive_firstSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_historicalArchive_isOrganizationRequest_label
					}
				</b>
				<br />
				{getLabelFromList(
					radioIsOrganizationRequest,
					request.incidentForm?.isOrganizationRequest
				)}
			</p>

			{request.incidentForm?.isOrganizationRequest == true && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_lastName} </b>
						<br />
						{request.incidentForm?.organizationName}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_firstName} </b>
						<br />
						{request.incidentForm?.applicantPurpose}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_historicalArchive_secondSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_historicalArchive_specifiedDocuments_label
					}{' '}
				</b>
				<br />
				{request.incidentForm?.specifiedDocuments}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{pageAssets.request_form_historicalArchive_researchSubject_label}{' '}
				</b>
				<br />
				{request.incidentForm?.researchSubject}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{pageAssets.request_form_historicalArchive_concernedSector_label}{' '}
				</b>
				<br />
				{request.incidentForm?.concernedSector}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_historicalArchive_coveredSearchPeriod_label
					}{' '}
				</b>
				<br />
				{pageUtils.getLocaleDate(
					request.incidentForm?.coveredSearchPeriodStart!
				) +
					' - ' +
					pageUtils.getLocaleDate(
						request.incidentForm?.coveredSearchPeriodEnd!
					)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_historicalArchive_requiresTechnicalSpecs_label
					}{' '}
				</b>
				<br />
				{request.incidentForm?.requiresTechnicalSpecs}
				{getLabelFromList(
					radioRequiresTechnicalSpecs,
					request.incidentForm?.requiresTechnicalSpecs
				)}
			</p>

			{request.incidentForm?.requiresTechnicalSpecs == true && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{' '}
						{
							pageAssets.request_form_historicalArchive_technicalSpecs_label
						}{' '}
					</b>
					<br />
					{request.incidentForm?.technicalSpecs}
				</p>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_historicalArchive_thirdSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_historicalArchive_isInPersonAppointment_label
					}{' '}
				</b>
				<br />
				{getLabelFromList(
					radioIsInPersonAppointment,
					request.incidentForm?.isInPersonAppointment
				)}
			</p>

			{request.incidentForm?.isInPersonAppointment == true && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_appointmentDate_label
							}{' '}
						</b>
						<br />
						{pageUtils.getLocaleDate(
							request.incidentForm?.appointmentDate!
						)}{' '}
						{getAppointmentTime(request.incidentForm?.appointmentDate)}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_appointmentComment_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.appointmentComment}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_historicalArchive_fourthSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{' '}
					{
						pageAssets.request_form_historicalArchive_areDocumentsDiffused_label
					}{' '}
				</b>
				<br />
				{request.incidentForm?.areDocumentsDiffused}
				{getLabelFromList(
					radioAreDocumentsDiffused,
					request.incidentForm?.areDocumentsDiffused
				)}
			</p>

			{request.incidentForm?.areDocumentsDiffused == true && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_isLicenceRecipientApplicant_label
							}{' '}
						</b>
						<br />
						{getLabelFromList(
							radioIsLicenceRecipientApplicant,
							request.incidentForm?.isLicenceRecipientApplicant?.toString()
						)}
					</p>

					{request.incidentForm?.isLicenceRecipientApplicant?.toString() ==
						REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson && (
						<>
							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_lastName} </b>
								<br />
								{request.incidentForm?.concernedLastName}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_firstName} </b>
								<br />
								{request.incidentForm?.concernedFirstName}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_email} </b>
								<br />
								{request.incidentForm?.concernedEmail}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_telephone} </b>
								<br />
								{request.incidentForm?.concernedPhoneNumber}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_address} </b>
								<br />
								{request.incidentForm?.concernedAdresse}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {formatStrapiText(pageAssets?.label_apartment_office)} </b>
								<br />
								{request.incidentForm?.concernedAppartement}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_city} </b>
								<br />
								{request.incidentForm?.concernedCity}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_state} </b>
								<br />
								{request.incidentForm?.concernedProvince}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_postalCode} </b>
								<br />
								{request.incidentForm?.concernedPostalCode}
							</p>
						</>
					)}

					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.request_form_historicalArchive_useType_label} </b>
						<br />

						{request.incidentForm?.typeOfUse?.split(',')?.map((answer) => {
							return (
								<>
									{
										pageAssets[
											useTypeListAndValues.find((list) => list.value === answer)
												?.key!
										]
									}
									<br />
								</>
							)
						})}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_projectTitle_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.projectTitle}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_projectDescription_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.projectDescription}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{
								pageAssets.request_form_historicalArchive_publishingHouse_label
							}{' '}
						</b>
						<br />
						{request.incidentForm?.publishingHouse}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{pageAssets.request_form_historicalArchive_releaseDate_label}{' '}
						</b>
						<br />
						{pageUtils.getLocaleDate(request.incidentForm?.releaseDate!)}
					</p>
				</>
			)}
		</>
	)
}

export default HistoricalArchiveFormDetails
