import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import Text from '@components/ui/text'

const FireplaceDeclarationFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const getKeysFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.key)
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const heatingSourceTypeListAndValues = [
		{
			key: 'request_form_fireplaceDeclaration_electricity',
			value: REQUEST_CONFIGS_KEYS.heatingSource.electricity,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_natural_gas',
			value: REQUEST_CONFIGS_KEYS.heatingSource.naturalGas,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_propane',
			value: REQUEST_CONFIGS_KEYS.heatingSource.propane,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_oil_fuel',
			value: REQUEST_CONFIGS_KEYS.heatingSource.oilFuel,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_dual_energy',
			value: REQUEST_CONFIGS_KEYS.heatingSource.dualEnergy,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_wood_pellets',
			value: REQUEST_CONFIGS_KEYS.heatingSource.woodPellets,
			checked: false
		},
		{
			key: 'request_form_fireplaceDeclaration_others',
			value: REQUEST_CONFIGS_KEYS.heatingSource.others,
			checked: false
		}
	]

	const declarationTypeList: RadioList[] = [
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option1,
			value: REQUEST_CONFIGS_KEYS.declarationType.possess,
			key: 'request_form_fireplaceDeclaration_declarationType_option1'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option2,
			value: REQUEST_CONFIGS_KEYS.declarationType.replaced,
			key: 'request_form_fireplaceDeclaration_declarationType_option2'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option3,
			value: REQUEST_CONFIGS_KEYS.declarationType.removed,
			key: 'request_form_fireplaceDeclaration_declarationType_option3'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option4,
			value: REQUEST_CONFIGS_KEYS.declarationType.neverHad,
			key: 'request_form_fireplaceDeclaration_declarationType_option4'
		}
	]

	const deviceReplacementTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_wood_pellets,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption,
			key: 'request_form_fireplaceDeclaration_wood_pellets'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_electricity,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.secondOption,
			key: 'request_form_fireplaceDeclaration_electricity'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_natural_gas,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.thirdOption,
			key: 'request_form_fireplaceDeclaration_natural_gas'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_propane,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.fourthOption,
			key: 'request_form_fireplaceDeclaration_propane'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_oil_fuel,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.fifthOption,
			key: 'request_form_fireplaceDeclaration_oil_fuel'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_others,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.sixthOption,
			key: 'request_form_fireplaceDeclaration_others'
		}
	]

	const fireplaceTypeToDeclareList: RadioList[] = [
		{
			// Chaudière à bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_firstOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.firstOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_firstOption'
		},
		{
			// Cuisinière au bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_secondOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.secondOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_secondOption'
		},
		{
			// Foyer au bois muni d'un appareil encastré
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_thirdOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirdOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_thirdOption'
		},
		{
			// Foyer de maçonnerie (ouvert en brique ou en pierre, sans appareil à l’intérieur)
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fourthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fourthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_fourthOption'
		},
		{
			// Foyer de masse
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fifthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fifthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_fifthOption'
		},
		{
			// Foyer décoratif (foyer d'ambiance)
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_sixthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.sixthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_sixthOption'
		},
		{
			// Four à bois commercial
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_seventhOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.seventhOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_seventhOption'
		},
		{
			// Fournaise au bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eighthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eighthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_eighthOption'
		},
		{
			// Poêle à bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_ninthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.ninthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_ninthOption'
		},
		{
			// Poêle à granules
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_tenthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.tenthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_tenthOption'
		},
		{
			// Poêle ou foyer à combustion lente
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eleventhOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eleventhOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_eleventhOption'
		},
		{
			// Poêle ou foyer à l'anthracite
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_twelfthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.twelfthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_twelfthOption'
		},
		{
			// Autre
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_thirteenthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_thirteenthOption'
		}
	]

	const frequencyOfUseList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option1,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.firstOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option2,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.secondOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option3,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.thirdOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option3'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option4,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.fourthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option4'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option5,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.fifthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option5'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option6,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.sixthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option6'
		}
	]

	const deviceLocationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option1,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.firstOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option2,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.secondOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option3,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.thirdOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option3'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option4,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.fourthOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option4'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option5,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option5'
		}
	]

	const fireplaceTypeListA = [
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.firstOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.secondOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirdOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.sixthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.seventhOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eighthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.ninthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.tenthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eleventhOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.twelfthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption
	]

	const fireplaceTypeListB = [
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fourthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fifthOption
	]

	const isDeviceCertifiedList: RadioList[] = [
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option1,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.firstOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option2,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.secondOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option3,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.thirdOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option3'
		}
	]

	const deviceCertificationsList: RadioList[] = [
		{
			label:
				pageAssets?.select_fireplaceDeclaration_deviceCertifications_option1,
			value: REQUEST_CONFIGS_KEYS.deviceCertifications.firstOption,
			key: 'select_fireplaceDeclaration_deviceCertifications_option1'
		},
		{
			label:
				pageAssets?.select_fireplaceDeclaration_deviceCertifications_option2,
			value: REQUEST_CONFIGS_KEYS.deviceCertifications.secondOption,
			key: 'select_fireplaceDeclaration_deviceCertifications_option2'
		}
	]

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_declaration_adresse}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_isSameAdresse_label}</b>
				<br />
				{
					pageAssets[
						radioListYesNo.find(
							(buildingAddressSameHasApplicant) =>
								buildingAddressSameHasApplicant.value ==
								request.incidentForm?.buildingAddressSameHasApplicant?.toString()
						)?.key!
					]
				}
			</p>
			{request.incidentForm?.buildingAddressSameHasApplicant == false && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b> {pageAssets.label_address} </b>
						<br />
						{request.incidentForm?.buildingAddress?.address}
					</p>

					{request.incidentForm?.buildingAddress?.appartment && (
						<p className={pageUtils.classes.paragraph}>
							<b>{`${formatStrapiText(pageAssets?.label_apartment_office)}`}</b>
							<br />
							{request.incidentForm?.buildingAddress.appartment}
						</p>
					)}
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{pageAssets.request_form_oilHeatingDeclaration_isBuildingOwner_label}
				</b>
				<br />
				{
					pageAssets[
						radioListYesNo.find(
							(isBuildingOwner) =>
								isBuildingOwner.value ==
								request.incidentForm?.isBuildingOwner?.toString()
						)?.key!
					]
				}
			</p>

			{request.incidentForm?.isBuildingOwner?.toString() == 'false' && (
				<>
					<p>
						<b>{pageAssets?.label_lastName}</b>
						<br />
						{request.incidentForm?.buildingOwner?.last_name}
					</p>

					<p>
						<b>{pageAssets?.label_firstName}</b>
						<br />
						{request.incidentForm?.buildingOwner?.first_name}
					</p>

					<p>
						<b>{pageAssets?.label_email}</b>
						<br />
						{request.incidentForm?.buildingOwner?.email}
					</p>

					<p>
						<b>{pageAssets?.label_telephone}</b>
						<br />
						{request.incidentForm?.buildingOwner?.telephone}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_fireplaceDeclaration_secondSubStep_title}
			</h4>

			<p>
				<b>
					{pageAssets?.request_form_fireplaceDeclaration_heatingSource_label}
				</b>
				<>
					{getKeysFromString(
						heatingSourceTypeListAndValues,
						request.incidentForm?.heatingSource
					).map((answerLabel, index) => (
						<>
							<br />
							{pageAssets[answerLabel]}
						</>
					))}
				</>
			</p>

			{request.incidentForm?.heatingSource?.includes(
				REQUEST_CONFIGS_KEYS.heatingSource.others
			) && (
				<p>
					<>{request.incidentForm?.otherHeatingSource}</>
				</p>
			)}

			{getKeysFromString(
				heatingSourceTypeListAndValues,
				request.incidentForm?.heatingSource
			).length == 2 && (
				<>
					<p>
						<b>
							{pageAssets?.request_form_fireplaceDeclaration_heatingSourcePercentage_label +
								' «' +
								pageAssets[
									heatingSourceTypeListAndValues.find(
										(heatingSource) =>
											heatingSource.value ==
											request.incidentForm?.heatingSource?.split(',')[0]
									)?.key!
								] +
								'»?'}
						</b>
						<br />
						{request.incidentForm?.firstHeatingSourcePercentage}
					</p>

					<p>
						<b>
							{pageAssets?.request_form_fireplaceDeclaration_heatingSourcePercentage_label +
								' «' +
								pageAssets[
									heatingSourceTypeListAndValues.find(
										(heatingSource) =>
											heatingSource.value ==
											request.incidentForm?.heatingSource?.split(',')[1]
									)?.key!
								] +
								'»?'}
						</b>
						<br />
						{request.incidentForm?.secondHeatingSourcePercentage}
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_fireplaceDeclaration_thirdSubStep_title}
			</h4>

			<p>
				<b>
					{pageAssets.request_form_fireplaceDeclaration_declarationType_label}
				</b>
				<br />
				<>
					<Text
						content={getLabelFromList(
							declarationTypeList,
							request.incidentForm?.declarationType
						)}
					/>
				</>
			</p>

			{/*  ---------------  replace ------------- */}
			{request.incidentForm?.declarationType ==
				REQUEST_CONFIGS_KEYS.declarationType.replaced && (
				<>
					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets?.request_form_fireplaceDeclaration_declarationType_option2_title
						}
					</h5>
					<p>
						<b>
							{
								pageAssets?.request_form_fireplaceDeclaration_deviceReplacementType_label
							}
						</b>
						<br />
						{getLabelFromList(
							deviceReplacementTypeList,
							request.incidentForm?.deviceReplacementType
						)}
					</p>

					{!!request.incidentForm?.deviceWithdrawalDate && (
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_deviceReplacementDate_label
								}
							</b>
							<br />
							{pageUtils.getLocaleDate(
								request.incidentForm?.deviceWithdrawalDate!
							)}
						</p>
					)}
				</>
			)}

			{/*  ---------------  possess ------------- */}
			{request.incidentForm?.declarationType ==
				REQUEST_CONFIGS_KEYS.declarationType.possess && (
				<>
					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets?.request_form_fireplaceDeclaration_declarationType_option1_title
						}
					</h5>
				</>
			)}

			{
				//possess
				REQUEST_CONFIGS_KEYS.declarationType.possess ==
					request.incidentForm?.declarationType ||
				// replace + type == wood
				(REQUEST_CONFIGS_KEYS.declarationType.replaced ==
					request.incidentForm?.declarationType &&
					request.incidentForm?.deviceReplacementType ==
						REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption) ? (
					<>
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label
								}
							</b>
							<br />
							<>
								{getLabelFromList(
									fireplaceTypeToDeclareList,
									request.incidentForm?.fireplaceTypeToDeclare
								)}
							</>
						</p>

						{request.incidentForm?.fireplaceTypeToDeclare ==
							REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption && (
							<p>
								<b>
									{
										pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label
									}
								</b>
								<br />
								<>{request.incidentForm?.otherFireplaceTypeToDeclare}</>
							</p>
						)}

						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_frequencyOfUse_label
								}
							</b>
							<br />
							{getLabelFromList(
								frequencyOfUseList,
								request.incidentForm?.frequencyOfUse
							)}
						</p>
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_installationYear_label
								}
							</b>
							<br />
							<>{request.incidentForm?.installationYear}</>
						</p>
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_deviceLocation_label
								}
							</b>
							<br />
							<>
								{getLabelFromList(
									deviceLocationList,
									request.incidentForm?.deviceLocation
								)}
							</>
						</p>

						{request.incidentForm?.deviceLocation ==
							REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption && (
							<p>
								<b>
									{
										pageAssets?.request_form_fireplaceDeclaration_otherDeviceLocation_label
									}
								</b>
								<br />
								<>{request.incidentForm?.otherDeviceLocation}</>
							</p>
						)}

						{
							/* liste A */
							/* le sous titre suivant : Fabricant et modèle */
							/* le sous titre suivant : Normes environnementales  */
							fireplaceTypeListA.includes(
								request.incidentForm?.fireplaceTypeToDeclare!.toString()
							) && (
								<>
									{(!!request.incidentForm?.deviceManifacturer ||
										!!request.incidentForm?.deviceModel) && (
										<>
											<h6 className={pageUtils.classes.h5}>
												{
													pageAssets?.request_form_fireplaceDeclaration_manufacturerAndModel_title
												}
											</h6>

											{!!request.incidentForm?.deviceManifacturer && (
												<p>
													<b>
														{
															pageAssets?.request_form_fireplaceDeclaration_deviceManifacturer_label
														}
													</b>
													<br />

													<>{request.incidentForm?.deviceManifacturer}</>
												</p>
											)}
											{request.incidentForm?.deviceModel && (
												<p>
													<b>
														{
															pageAssets?.request_form_fireplaceDeclaration_deviceModel_label
														}
													</b>
													<br />

													<>{request.incidentForm?.deviceModel}</>
												</p>
											)}
										</>
									)}

									<>
										<h6 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_fireplaceDeclaration_environmentalStandards_title
											}
										</h6>

										<p>
											<b>
												{
													pageAssets?.request_form_fireplaceDeclaration_isDeviceCertified_label
												}
											</b>
											<br />

											{getLabelFromList(
												isDeviceCertifiedList,
												request.incidentForm?.isDeviceCertified
											)}
										</p>

										{request.incidentForm?.isDeviceCertified ==
											REQUEST_CONFIGS_KEYS.isDeviceCertified.firstOption && (
											<p>
												<b>
													{
														pageAssets?.request_form_fireplaceDeclaration_deviceCertifications_label
													}
												</b>
												<br />

												{getLabelsFromString(
													deviceCertificationsList,
													request.incidentForm?.deviceCertifications
												).map((answerLabel, index) => {
													return (
														<p key={`${index}-deviceCertifications`}>
															{answerLabel}
														</p>
													)
												})}
											</p>
										)}
										{!!request.incidentForm?.deviceParticleEmissionRate && (
											<p>
												<b>
													{
														pageAssets?.request_form_fireplaceDeclaration_deviceParticleEmissionRate_label
													}
												</b>
												<br />

												{request.incidentForm?.deviceParticleEmissionRate +
													' ' +
													pageAssets?.request_form_fireplaceDeclaration_deviceParticleEmissionRate_unit_label}
											</p>
										)}
									</>
								</>
							)
						}

						{/* liste B */}
						{fireplaceTypeListB.includes(
							request.incidentForm?.fireplaceTypeToDeclare!.toString()
						) &&
							!!request.incidentForm?.deviceContractorName && (
								<p>
									<b>
										{
											pageAssets?.request_form_fireplaceDeclaration_deviceContractorName_label
										}
									</b>
									<br />

									{request.incidentForm?.deviceContractorName}
								</p>
							)}
					</>
				) : (
					''
				)
			}

			{REQUEST_CONFIGS_KEYS.declarationType.removed ==
				request.incidentForm?.declarationType && (
				<>
					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets?.request_form_fireplaceDeclaration_declarationType_option3_title
						}
					</h5>

					<p>
						<b>
							{
								pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label
							}
						</b>
						<br />

						<>
							{getLabelFromList(
								fireplaceTypeToDeclareList,
								request.incidentForm?.fireplaceTypeToDeclare
							)}
						</>
					</p>

					{request.incidentForm?.fireplaceTypeToDeclare ==
						REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption && (
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label
								}
							</b>
							<br />

							<>{request.incidentForm?.otherFireplaceTypeToDeclare}</>
						</p>
					)}
					{!!request.incidentForm?.deviceWithdrawalDate && (
						<p>
							<b>
								{
									pageAssets?.request_form_fireplaceDeclaration_deviceWithdrawalDate_label
								}
							</b>
							<br />

							{pageUtils.getLocaleDate(
								request.incidentForm?.deviceWithdrawalDate!
							)}
						</p>
					)}
				</>
			)}
		</>
	)
}

export default FireplaceDeclarationFormDetails
