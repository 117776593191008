import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { RadioList } from '@utils/request'
import { formatStrapiText, getLabelFromList } from '@utils/methods'

const HydraulicAndPressureTestsFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_company_information}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_annualRegistrationCertificateForContractors_companyName_label
					}
				</b>
				<br />
				<span>{request.incidentForm?.business?.organization_name}</span>
			</p>
			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_isSameAdresse_label}</b>
				<br />
				<span>
					{getLabelFromList(
						radioListYesNo,
						request.incidentForm?.businessSameAsPersonal
					)}
				</span>
			</p>

			{request.incidentForm?.businessSameAsPersonal?.toString() == 'false' && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.label_address}</b>
						<br />
						{request.incidentForm?.business?.address}
						<br />

						{/* en cas de adresse en dehors de Laval */}
						{!!request.incidentForm?.business?.city && (
							<>{request.incidentForm?.business?.city} </>
						)}
						{!!request.incidentForm?.business?.postal_code && (
							<>{request.incidentForm?.business?.postal_code} </>
						)}
						{!!request.incidentForm?.business?.state && (
							<>{request.incidentForm?.business?.state} </>
						)}
						{!!request.incidentForm?.business?.country && (
							<>
								{request.incidentForm?.business?.country} <br />
							</>
						)}
						{/* ---------------------------------- */}

						{request.incidentForm?.business?.appartment
							? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
									request.incidentForm?.business?.appartment
							  }`
							: ''}
					</p>
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_hydraulicAndPressureTest_is_this_a_contract_with_city
					}
				</b>
				<br />
				<span>
					{
						radioListYesNo.find(
							(radio) =>
								radio.value ==
								request.incidentForm?.isContractWithCity?.toString()
						)?.label
					}
				</span>
			</p>

			{request.incidentForm?.isContractWithCity && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_hydraulicAndPressureTest_contract_number}
						</b>
						<br />
						<span>{request.incidentForm?.contractNumber}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_hydraulicAndPressureTest_name_of_respondent_at_city
							}
						</b>
						<br />
						<span>{request.incidentForm?.cityRespondentName}</span>
					</p>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{
					pageAssets?.request_form_hydraulicAndPressureTest_hydraulic_test_information
				}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_hydraulicAndPressureTest_desiredDateForHydraulicTest_label
					}
				</b>
				<br />
				<span>
					{pageUtils.getLocaleDate(
						request.incidentForm?.desiredDateForHydraulicTest!
					)}
				</span>
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_hydraulicAndPressureTest_installing_a_temporary_network
					}
				</b>
				<br />
				<span>
					{
						radioListYesNo.find(
							(radio) =>
								radio.value ==
								request.incidentForm?.isRelatedToInstallingTemporaryNetwork?.toString()
						)?.label
					}
				</span>
			</p>

			{request.incidentForm?.hydraulicTestList?.map((result, index) => (
				<p className={pageUtils.classes.paragraph} key={index}>
					<b>{`${pageAssets.request_form_hydraulicAndPressureTest_label} #${
						index + 1
					}`}</b>
					<br />
					<span>{result}</span>
				</p>
			))}
		</>
	)
}

export default HydraulicAndPressureTestsFormDetails
