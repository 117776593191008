import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import { RadioList } from '@utils/request'
import * as formUtils from '../../requestForms/__forms.utils'
import TableDetailsModal from '../modals/tableDetailsModal'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const CarSharingFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const grantForWhomList: RadioList[] = [
		{
			label: pageAssets?.select_carSharing_forWhom_firstOption,
			value: REQUEST_CONFIGS_KEYS.carSharingForWhom.firstOption,
			key: 'select_carSharing_forWhom_firstOption'
		},
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_secondOption,
			value: REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption,
			key: 'select_sustainableHygiene_forWhom_secondOption',
			extraText:
				pageAssets?.select_sustainableHygiene_forWhom_secondOption_extra
		}
	]

	const radioListSubsidyReceived: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const isGrantforMyself = () => {
		return (
			request.incidentForm?.grantForWhom?.toString() ==
			REQUEST_CONFIGS_KEYS.carSharingForWhom.firstOption
		)
	}

	const isGrantforBeneficiary = () => {
		return (
			request.incidentForm?.grantForWhom?.toString() ==
			REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption
		)
	}

	const getLabelsFromString = (
		list: RadioList[],
		answer?: string
	): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const [isFirstModalOpen, setIsFirstModalOpen] = useState<boolean>(false)

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_sustainableHygiene_forWhom_label}</b>
				<br />
				<span>
					{getLabelFromList(
						grantForWhomList,
						request.incidentForm?.grantForWhom
					)}
				</span>
			</p>

			{isGrantforBeneficiary() && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_fireHydrantsTankFilling_step2_title}
					</h4>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_firstName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryFirstName}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_lastName}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryLastName}</span>
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_sustainableHygiene_beneficiary_address}
						</b>
						<br />
						<span>{request.incidentForm?.beneficiaryAddress}</span>
					</p>

					{request.incidentForm?.beneficiaryAppartment && (
						<p className={pageUtils.classes.paragraph}>
							<b>{pageAssets?.apartment_label}</b>
							<br />
							{request.incidentForm?.beneficiaryAppartment}
						</p>
					)}
				</>
			)}

			{/* Pour moi-même ( Juste le titre ) */}
			{isGrantforMyself() && (
				<>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.select_form_sustainableHygiene_situation_firstOption}
					</h4>
				</>
			)}

			{/* Pour moi-même OU autre Beneficiare */}
			{(isGrantforBeneficiary() || isGrantforMyself()) && (
				<>
					<div>
						<strong>
							{isGrantforBeneficiary()
								? pageAssets?.request_form_carSharing_beneficiary_subsidyReceived_label
								: pageAssets?.request_form_carSharing_myself_subsidyReceived_label}
						</strong>
						<br />
						<span>
							{getLabelFromList(
								radioListSubsidyReceived,
								request.incidentForm?.subsidyReceived
							)}
						</span>
					</div>
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_carSharing_subscription_fees_Info}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_carSharing_subscriptionDate_label}</b>
				<br />
				<span>
					{pageUtils.getLocaleDate(
						request.incidentForm?.subscriptionDate ?? ''
					)}
				</span>
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_carSharing_subscriptionAmount_label}</b>
				<br />
				<span>{request.incidentForm?.subscriptionAmount}</span>
			</p>
		</>
	)
}

export default CarSharingFormDetails
