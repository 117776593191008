import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'

const PurchaseOrSellFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListLotRequestReason: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_lotRequestReason_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.requestReason.purchase,
			key: 'request_form_buildingTransactionRequest_lotRequestReason_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_lotRequestReason_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.requestReason.sell,
			key: 'request_form_buildingTransactionRequest_lotRequestReason_secondOption_label'
		}
	]

	const radioListIsApplicantLotOwner: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_label_firstOption_label,
			value: 'true',
			key: 'request_form_buildingTransactionRequest_isApplicantLotOwner_label_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_isApplicantLotOwner_label_secondOption_label,
			value: 'false',
			key: 'request_form_buildingTransactionRequest_isApplicantLotOwner_label_secondOption_label'
		}
	]

	const radioListLandMeasurements: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landMeasurements_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.propertyMeasureUnit.meters,
			key: 'request_form_buildingTransactionRequest_landMeasurements_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landMeasurements_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.propertyMeasureUnit.feet,
			key: 'request_form_buildingTransactionRequest_landMeasurements_secondOption_label'
		}
	]

	const radioListLandAreaDimensions: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit.squaredMeters,
			key: 'request_form_buildingTransactionRequest_landAreaDimensions_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_buildingTransactionRequest_landAreaDimensions_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.propertySurfaceMeasureUnit.squaredFeet,
			key: 'request_form_buildingTransactionRequest_landAreaDimensions_secondOption_label'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value.toString())?.label
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_buildingTransactionRequest_firstSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_buildingTransactionRequest_lotRequestReason_label
					}
				</b>
				<br />
				{getLabelFromList(
					radioListLotRequestReason,
					request.incidentForm?.lotRequestReason
				)}
			</p>

			{request.incidentForm?.isApplicantLotOwner == false && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_buildingTransactionRequest_isApplicantLotOwner_label
							}
						</b>
						<br />
						{getLabelFromList(
							radioListIsApplicantLotOwner,
							request.incidentForm?.isApplicantLotOwner
						)}
					</p>
					{request.incidentForm?.relationToOwner != '' && (
						<>
							<p className={pageUtils.classes.paragraph}>
								<b>
									{' '}
									{
										pageAssets.request_form_buildingTransactionRequest_relationToOwnerInput_label
									}{' '}
								</b>
								<br />
								{request.incidentForm?.relationToOwner}
							</p>
						</>
					)}
					{request.incidentForm?.concernedInfo && (
						<>
							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_firstName} </b>
								<br />
								{request.incidentForm?.concernedInfo?.first_name}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_lastName} </b>
								<br />
								{request.incidentForm?.concernedInfo?.last_name}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_email} </b>
								<br />
								{request.incidentForm?.concernedInfo?.email}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_telephone} </b>
								<br />
								{request.incidentForm?.concernedInfo?.telephone}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b> {pageAssets.label_address} </b>
								<br />
								{request.incidentForm?.concernedInfo?.address}
							</p>

							{request.incidentForm.concernedInfo.appartment && (
								<p className={pageUtils.classes.paragraph}>
									<b>
										{' '}
										{formatStrapiText(pageAssets?.label_apartment_office)}{' '}
									</b>
									<br />
									{request.incidentForm?.concernedInfo?.appartment}
								</p>
							)}

							{request.incidentForm.concernedInfo.city && (
								<p className={pageUtils.classes.paragraph}>
									<b> {formatStrapiText(pageAssets?.label_city)} </b>
									<br />
									{request.incidentForm?.concernedInfo?.city}
								</p>
							)}

							{request.incidentForm.concernedInfo.postalCode && (
								<p className={pageUtils.classes.paragraph}>
									<b> {formatStrapiText(pageAssets?.label_postalCode)} </b>
									<br />
									{request.incidentForm?.concernedInfo?.postalCode}
								</p>
							)}

							{request.incidentForm.concernedInfo.state && (
								<p className={pageUtils.classes.paragraph}>
									<b> {formatStrapiText(pageAssets?.label_state)} </b>
									<br />
									{request.incidentForm?.concernedInfo?.state}
								</p>
							)}

							{request.incidentForm.concernedInfo.country && (
								<p className={pageUtils.classes.paragraph}>
									<b> {formatStrapiText(pageAssets?.label_country)} </b>
									<br />
									{request.incidentForm?.concernedInfo?.country}
								</p>
							)}
						</>
					)}
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_buildingTransactionRequest_secondSubStep_title}
			</h4>
			{request?.incidentForm?.lotNumbersList?.map((lotNumber, index) => {
				if (lotNumber !== '')
					return (
						<>
							<p className={pageUtils.classes.paragraph}>
								<b>
									{pageAssets?.request_form_buildingTransactionRequest_lotNumbers_label +
										` ${index + 1}`}
								</b>
								<br />
								{lotNumber}
								<br />
							</p>
						</>
					)
			})}

			{request.incidentForm?.registrationNumber && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_buildingTransactionRequest_registrationNumber_label
						}
					</b>
					<br />
					{request.incidentForm?.registrationNumber}
				</p>
			)}

			{request.incidentForm?.landAreaDimensions && (
				<p className={pageUtils.classes.paragraph}>
					<b>
						{
							pageAssets.request_form_buildingTransactionRequest_landAreaDimensions_label
						}
					</b>
					<br />
					{request.incidentForm?.landAreaDimensions}
					{getLabelFromList(
						radioListLandAreaDimensions,
						request.incidentForm?.landAreaMeasurements
					)}
				</p>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_buildingTransactionRequest_requestObject_label
					}
				</b>
				<br />
				{request.incidentForm?.requestObject}
			</p>
		</>
	)
}

export default PurchaseOrSellFormDetails
