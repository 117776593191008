import React, { FC, useContext, useState } from 'react'
import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import Tooltip from '@components/ui/tooltip'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Text from '@components/ui/text'
import ConsentForm from '@components/ui/consent/form'
import { RequestConsent } from '@services/models'
import { formatStrapiText } from '@utils/methods'
import RevokeModal from '@components/ui/consent/revokeModal'

type ConsentProp = {
	className?: string
	onAddNewConsent: any
	consentList: RequestConsent[]
}

type Classes = {
	root: string
	h3: string
	list: string
	listItemCenter: string
	firstListItem: string
	lastListItem: string
	listItemRemoved: string
	btn: string
	btnAdd: string
	noBorderTop: string
	elu: string
	eluName: string
	eluButton: string
	revoqued: string
}

const classes: Classes = makeClasses({
	root: {
		background: Colors.white
	},
	h3: {
		display: 'flex',
		alignItems: 'center',
		color: Colors.primary,
		marginTop: 0,
		marginBottom: '25px'
	},
	list: {
		display: 'flex',
		alignItem: 'center',
		justifyContent: 'space-between',
		fontSize: '14px',
		color: Colors.black2,
		lineHeight: 1,
		padding: '10px 0',
		borderTop: `1px solid ${Colors.greyIron}`,
		span: {
			alignSelf: 'center'
		}
	},
	listItemCenter: {
		width: '100%',
		maxWidth: '40%'
	},
	firstListItem: {
		width: '100%',
		maxWidth: '35%',
		fontSize: '16px',
		fontWeight: 500
	},
	lastListItem: {
		width: '100%',
		maxWidth: '25%',
		textAlign: 'right'
	},
	listItemRemoved: {
		padding: '15px 0',
		color: Colors.lightGrey2
	},
	btn: {
		...ButtonStyle,
		color: Colors.secondary,
		borderColor: Colors.secondary,
		justifyContent: 'center',
		width: 'auto',
		fontSize: '14px',
		padding: '10px 20px',
		'&:hover': {
			color: Colors.white,
			background: Colors.primary,
			borderColor: Colors.primary,
			cursor: 'pointer'
		}
	},
	btnAdd: {
		...ButtonStyle,
		background: Colors.secondary,
		color: Colors.white,
		borderColor: Colors.secondary,
		fontSize: '14px',
		padding: '10px 20px',
		marginTop: '15px',
		'&:hover': {
			background: Colors.primary,
			borderColor: Colors.primary,
			cursor: 'pointer'
		}
	},
	noBorderTop: {
		borderTop: 0
	},
	revoqued: {
		color: Colors.lightGrey2
	},
	elu: {
		width: '100%',
		borderCollapse: 'collapse',
		lineHeight: '1.62em',
		'& tr': {
			borderTop: `1px solid ${Colors.greyIron}`
		},
		'& tr:last-child': {
			borderBottom: `1px solid ${Colors.greyIron}`
		},
		'& td:first-child': {
			paddingRight: '10px'
		},
		'& td:last-child': {
			textAlign: 'right'
		},
		'& td': {
			padding: '5px 0',
			background: 'none',
			fontSize: '13px'
		},
		[Breakpoints.maxWidth('lg')]: {
			'& td:first-child': {
				paddingBottom: 0,
				paddingRight: '5px',
				width: '60%',
				display: 'block'
			},
			'& td:nth-child(2)': {
				paddingTop: 0,
				display: 'block'
			}
		}
	},
	eluName: {
		fontSize: '16px !important',
		fontWeight: 500
	}
})

const Consent: FC<ConsentProp> = ({
	className,
	onAddNewConsent,
	consentList
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [consentToRevoke, setConsentToRevoke] = useState<
		RequestConsent | undefined
	>()
	const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false)

	return (
		<section
			className={joinClasses([classes.root, className ? className : ''])}
		>
			<h3 className={classes.h3}>
				{formatStrapiText(pageData?.assets?.my_request_consent_title)}
				<Tooltip
					showInfo
					infoLabel={pageData?.assets?.my_request_consent_tooltip_text}
				/>
			</h3>
			{!!consentList?.length && (
				<table className={classes.elu}>
					<tbody>
						{consentList.map((consent: RequestConsent, index: number) => (
							<tr
								key={index}
								className={consent.isRevoked ? classes.revoqued : ''}
							>
								<td className={classes.eluName}>
									{consent.electedTeamMember?.name}
								</td>
								<td>
									<Text
										content={`${pageData?.assets?.added_at} ${consent.dateAdd?.localDateAndTimeString}`}
									/>
								</td>
								<td>
									{!consent.isRevoked && (
										<button
											type="button"
											className={classes.btn}
											onClick={() => {
												setConsentToRevoke(consent)
												setTimeout(() => {
													setIsRevokeModalOpen(true)
												}, 10)
											}}
										>
											{formatStrapiText(
												pageData?.assets?.my_request_consent_btn_revoke
											)}
										</button>
									)}
									{consent.isRevoked && (
										<>
											<Text
												content={`${pageData?.assets?.revoked_on} ${consent.dateRevoked?.localDateAndTimeString}`}
											/>
										</>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			{consentList?.length === 0 && (
				<div>
					<Text content={pageData?.assets?.my_request_consent_no_consent} />
				</div>
			)}

			<button
				className={classes.btnAdd}
				type="button"
				onClick={() => setIsModalOpen(true)}
			>
				{formatStrapiText(pageData?.assets?.my_request_consent_add_consent)}
			</button>

			<ConsentForm
				isModalOpen={isModalOpen}
				onSetIsModalOpen={setIsModalOpen}
				onAddNewConsent={onAddNewConsent}
			/>
			{consentToRevoke && (
				<RevokeModal
					consent={consentToRevoke}
					isOpen={isRevokeModalOpen}
					onClose={() => setIsRevokeModalOpen(false)}
					onSetIsOpen={() => {}}
				/>
			)}
		</section>
	)
}

export default Consent
