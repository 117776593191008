import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { generateYearList } from '@components/requestForms/__forms.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'

const MunicipalEvaluationFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListApplicantStatus: RadioList[] = [
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.owner,
			key: 'request_form_municipalEvaluation_applicantStatus_firstOption_label',
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.ownersAgent,
			key: 'request_form_municipalEvaluation_applicantStatus_secondOption_label',
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.applicanStatus.other,
			key: 'request_form_municipalEvaluation_applicantStatus_thirdOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_applicantStatus_thirdOption_extraText_label,
			updateDetails: false
		}
	]

	const radioListOrigin: RadioList[] = [
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.newRole,
			key: 'request_form_municipalEvaluation_origin_firstOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_firstOption_extraText_label,
			updateDetails: true
		},

		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.changingRole,
			key: 'request_form_municipalEvaluation_origin_thirdOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_thirdOption_extraText_label,
			updateDetails: false
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_fourthOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole,
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_fourthOption_extraText_label,
			key: 'request_form_municipalEvaluation_origin_fourthOption_label',
			updateDetails: true
		},
		{
			label:
				pageAssets?.request_form_municipalEvaluation_origin_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.requestOrigin.modificationNotMade,
			key: 'request_form_municipalEvaluation_origin_secondOption_label',
			extraText:
				pageAssets?.request_form_municipalEvaluation_origin_secondOption_extraText_label,

			updateDetails: false
		}
	]
	const buildingValueToRoleList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_500kOrLess,
			value: REQUEST_CONFIGS_KEYS.buildingValues.less_than_500k,
			key: 'select_500kOrLess'
		},
		{
			label: pageAssets?.select_500kTo2mil,
			value: REQUEST_CONFIGS_KEYS.buildingValues.between_500k_and_2m,
			key: 'select_500kTo2mil'
		},
		{
			label: pageAssets?.select_2milTo5mil,
			value: REQUEST_CONFIGS_KEYS.buildingValues.between_2m_and_5m,
			key: 'select_2milTo5mil'
		},
		{
			label: pageAssets?.select_5milOrMore,
			value: REQUEST_CONFIGS_KEYS.buildingValues.above_5m,
			key: 'select_5milOrMore'
		}
	]

	const evaluationRoleList: SelectList[] = generateYearList('2019', '2024', 3).yearList

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_municipalEvaluation_firstSubstep_title}
			</h4>
			{request.incidentForm?.addressField && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets?.request_form_addressInfoSection_addressOption_firstOption_label
						}
					</strong>
					<br />
					<span>{request.incidentForm?.addressField}</span>
				</div>
			)}
			{request.incidentForm?.addressLotNumber && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets?.request_form_addressInfoSection_addressOption_secondOption_label
						}
					</strong>
					<br />
					<span>{request.incidentForm?.addressLotNumber}</span>
				</div>
			)}
			{request.incidentForm?.addressRegistrationNumber && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets?.request_form_addressInfoSection_addressOption_thirdOption_label
						}
					</strong>
					<br />
					<span>{request.incidentForm?.addressRegistrationNumber}</span>
				</div>
			)}
			{request.incidentForm?.addressAppartment && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{pageAssets?.label_apartment_office?.replace(/\*\*/g, '')}
					</strong>
					<br />
					<span>{request.incidentForm?.addressAppartment}</span>
				</div>
			)}

			{request.incidentForm?.applicantStatus && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{pageAssets?.request_form_municipalEvaluation_applicantStatus_label}
					</strong>
					<br />
					<span>
						{getLabelFromList(
							radioListApplicantStatus,
							request.incidentForm?.applicantStatus
						)}
					</span>
				</div>
			)}

			{request.incidentForm?.provideMoreInfo && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{pageAssets?.request_form_municipalEvaluation_provideMoreInfo_label}
					</strong>
					<br />
					<span>{request.incidentForm?.provideMoreInfo}</span>
				</div>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_municipalEvaluation_secondSubstep_title}
			</h4>

			{/* Check */}
			{request.incidentForm?.evaluationRole && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{pageAssets?.request_form_municipalEvaluation_evaluationRole_label}
					</strong>
					<br />
					<span>
						{getLabelFromList(
							evaluationRoleList,
							request.incidentForm?.evaluationRole
						)}
					</span>
				</div>
			)}
			{request.incidentForm?.requestOrigin && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{pageAssets?.request_form_municipalEvaluation_requestOrigin_label}
					</strong>
					<br />
					<span>
						{getLabelFromList(
							radioListOrigin,
							request.incidentForm?.requestOrigin
						)}
					</span>
				</div>
			)}
			{request.incidentForm?.requestOrigin ==
				REQUEST_CONFIGS_KEYS.requestOrigin.changingRole &&
				request.incidentForm?.roleModification && (
					<div>
						<strong>
							{
								pageAssets?.request_form_municipalEvaluation_roleModification_label
							}
						</strong>
						<br />
						<span>{request.incidentForm?.roleModification}</span>
					</div>
				)}

			{request.incidentForm?.requestOrigin ==
				REQUEST_CONFIGS_KEYS.requestOrigin.correctionOfRole &&
				request.incidentForm?.roleCorrection && (
					<div>
						<strong>
							{
								pageAssets?.request_form_municipalEvaluation_roleCorrection_label
							}
						</strong>
						<br />
						<span>{request.incidentForm?.roleCorrection}</span>
					</div>
				)}
			{/* ///////////////// */}

			<div className={pageUtils.classes.paragraph}>
				<strong>
					{pageAssets?.request_form_municipalEvaluation_omission_label}
				</strong>
				<br />
				{request.incidentForm?.omission && (
					<>
						<strong>
							{
								pageAssets?.request_form_municipalEvaluation_omission_firstOption_label
							}
						</strong>
						<br />
						<span>{request.incidentForm?.askerValue}</span>
					</>
				)}
				<br />
				{request.incidentForm?.omissionOther && (
					<>
						<strong>
							{
								pageAssets?.request_form_municipalEvaluation_omission_secondOption_label
							}
						</strong>
						<br />
						<span>{request.incidentForm?.otherInscription}</span>
					</>
				)}
			</div>

			{request.incidentForm?.requestSupportInfo && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets?.request_form_municipalEvaluation_requestSupportInfo_label
						}
					</strong>
					<br />
					<span>{request.incidentForm?.requestSupportInfo}</span>
				</div>
			)}

			{request.incidentForm?.buildingValueToRole && (
				<div className={pageUtils.classes.paragraph}>
					<strong>
						{
							pageAssets?.request_form_municipalEvaluation_buildingValueToRole_label
						}
					</strong>
					<br />
					<span>
						{getLabelFromList(
							buildingValueToRoleList,
							request.incidentForm?.buildingValueToRole
						)}
					</span>
				</div>
			)}
		</>
	)
}

export default MunicipalEvaluationFormDetails
