import React, { FC } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants/requests.constant'
import { SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'

const SustainableHygieneProductsFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const grantForWhomList = [
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_firstOption,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption,
			key: 'select_sustainableHygiene_forWhom_firstOption'
		},
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_secondOption,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption,
			key: 'select_sustainableHygiene_forWhom_secondOption',
			extraText:
				pageAssets?.select_sustainableHygiene_forWhom_secondOption_extra
		}
	]

	const situationsListAndValues = [
		{
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.firstOption,
			key: 'select_form_sustainableHygiene_situation_firstOption',
			checked: false
		},
		{
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption,
			key: 'select_form_sustainableHygiene_situation_secondOption',
			checked: false
		}
	]

	const productCategoryList = [
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_firstCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.firstCat,
			key: 'select_sustainableHygiene_buyingProducts_firstCat'
		},
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_secondCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.secondCat,
			key: 'select_sustainableHygiene_buyingProducts_secondCat'
		},
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_thirdCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.thirdCat,
			key: 'select_sustainableHygiene_buyingProducts_thirdCat'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getKeysFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.key)
	}

	return (
		<>
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_sustainableHygiene_beneficiaryInfo}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b> {pageAssets.request_form_sustainableHygiene_forWhom_label} </b>
				<br />
				{getLabelFromList(grantForWhomList, request.incidentForm?.grantForWhom)}
			</p>

			{request.incidentForm?.grantForWhom ===
				parseInt(
					REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption
				) && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{' '}
							{pageAssets.request_form_sustainableHygiene_situation_label}{' '}
						</b>

						{getKeysFromString(
							situationsListAndValues,
							request.incidentForm?.situationString
						).map((answerLabel, index) => (
							<>
								<br />
								{pageAssets[answerLabel]}
							</>
						))}
					</p>
					{request.incidentForm?.dependentFirstChildFirstName && (
						<>
							<b>
								{formatStrapiText(
									pageAssets?.request_form_sustainableHygiene_firstChild_label
								)}
							</b>
							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_firstName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentFirstChildFirstName}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_lastName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentFirstChildLastName}
							</p>
						</>
					)}

					{request.incidentForm?.dependentSecondChildFirstName && (
						<>
							<b>
								{formatStrapiText(
									pageAssets?.request_form_sustainableHygiene_secondChild_label
								)}
							</b>
							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_firstName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentSecondChildFirstName}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_lastName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentSecondChildLastName}
							</p>
						</>
					)}

					{request.incidentForm?.dependentThirdChildFirstName && (
						<>
							<b>
								{formatStrapiText(
									pageAssets?.request_form_sustainableHygiene_thirdChild_label
								)}
							</b>
							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_firstName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentThirdChildFirstName}
							</p>

							<p className={pageUtils.classes.paragraph}>
								<b>
									{
										pageAssets?.request_form_sustainableHygiene_dependentChild_lastName
									}{' '}
								</b>
								<br />
								{request.incidentForm?.dependentThirdChildLastName}
							</p>
						</>
					)}
				</>
			)}

			{request.incidentForm?.grantForWhom ===
				parseInt(
					REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
				) && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets?.request_form_sustainableHygiene_beneficiary_firstName
							}
						</b>
						<br />
						{request.incidentForm?.beneficiaryFirstName}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets?.request_form_sustainableHygiene_beneficiary_lastName}
						</b>
						<br />
						{request.incidentForm?.beneficiaryLastName}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets?.request_form_sustainableHygiene_beneficiary_address}
						</b>
						<br />
						{request.incidentForm?.beneficiaryAddress}
					</p>

					{request.incidentForm?.beneficiaryAppartment && (
						<p className={pageUtils.classes.paragraph}>
							<b>{pageAssets?.apartment_label}</b>
							<br />
							{request.incidentForm.beneficiaryAppartment}
						</p>
					)}
				</>
			)}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets?.request_form_sustainableHygiene_buyingProducts}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets?.request_form_sustainableHygiene_buyingProducts_category_label
					}
				</b>{' '}
				<br />
				{getLabelFromList(
					productCategoryList,
					request.incidentForm?.buyingProducts
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets?.request_form_sustainableHygiene_buyingProducts_totalAmount_label
					}
				</b>{' '}
				<br />
				{request.incidentForm?.totalAmount}
			</p>
		</>
	)
}

export default SustainableHygieneProductsFormDetails
